export type VideoVariants = {
  fullHD: string,
  hd: string,
  largeSD: string,
  sd: string,
};

export type VideoData = {
  desktop: VideoVariants,
  mobile: VideoVariants,
};

export type VideoFromVariants = 'mobile' | 'desktop';
export type VideoSource = 'fullHD' | 'hd' | 'largeSD' | 'sd';

export default function onLoad() {
  // home-hero .mobile .desktop
  const width = window.innerWidth;

  let from: VideoFromVariants;

  const promoteVariants = {
    sd: 'https://player.vimeo.com/external/407919861.sd.mp4?s=edffcb876809573da4dee4fa0a5a185a48236104&profile_id=164',
    largeSD: 'https://player.vimeo.com/external/407919861.sd.mp4?s=edffcb876809573da4dee4fa0a5a185a48236104&profile_id=165',
    hd: 'https://player.vimeo.com/external/407919861.hd.mp4?s=7724652ff258d6e7ddda35da637f7c4a8003c1f0&profile_id=174',
    fullHD: 'https://player.vimeo.com/external/407919861.hd.mp4?s=7724652ff258d6e7ddda35da637f7c4a8003c1f0&profile_id=175',
  };

  const videoPromoteBrand: VideoData = {
    desktop: promoteVariants,
    mobile: promoteVariants,
  };

  if (width <= 768) {
    // mobile
    from = 'mobile';
  } else {
    // desktop
    from = 'desktop';
  }

  const videoSourcePromoteBrand = videoPromoteBrand[from].sd;
  const videoElementPromoteBrand = document.querySelector('.made-with-kamua video') as HTMLVideoElement;
  videoElementPromoteBrand.src = videoSourcePromoteBrand;
  videoElementPromoteBrand.muted = true;
  videoElementPromoteBrand.load();

  // apparently on load the video plays
  // in case it didn't, play it again.
  setTimeout(() => {
    if (videoElementPromoteBrand.paused) {
      videoElementPromoteBrand.play().catch(e => { console.log('Play Error:', e); });
    }
  }, 2500);
}
