import debounce from 'lodash.debounce';
import {
  flow,
  observable,
  computed,
  action,
  reaction,
  runInAction,
  makeObservable,
} from 'mobx';
import { deepObserve, IDisposer } from 'mobx-utils';
import { matchPath } from 'react-router';
import { assertExists } from '../functions/asserts';

import {
  deleteToken as deleteUserToken,
  fetchie,
  getToken as getUserToken,
  setToken as setUserToken,
  setExpiredTokenHandler,
  parseJWT,
} from '../functions/fetchie';
import toJSClone from '../functions/toJSClone';
import { API } from './AiveHelpers';
import { maintainRequestState, RequestState, resetRequestState } from '../functions/requestState';
import AlertStore from './AlertStore';
import RouterStore from './RouterStore';
import type { UserEditableData, UserSanitized, UserSettings } from './UserTypes';
import type { MediaData } from './MediaTypes';
import type { ProjectData } from './Project';
import { isSignup, MAX_SAFE_INTEGER } from '../functions/helpers';
import { eventList } from '../tracking/events';

type LoginType = XOR<{
  email: string,
  pass: string
} & ({ register: false } | {
  register: true,
  redirect?: string,
  details?: UserEditableData,
}),
XOR<{
  facebookToken: string,
}, {
  googleToken: string,
}>
>;

export function userGetName(user: UserSanitized) {
  const { name } = user;
  if (name) {
    const { first, last } = name;
    if (first || last) {
      return `${first}${(first && last) ? ' ' : ''}${last}`;
    }
  }
  const { email } = user;
  if (email) {
    return email.replace(/[.@].*$/, '');
  }
  return 'User';
}

export function userGetInitials(user: UserSanitized) {
  const { name } = user;
  if (name) {
    const { first = '', last = '' } = name;
    if (first || last) {
      return first.substring(0, 1).toUpperCase() + last.substring(0, 1).toUpperCase();
    }
  }
  const { email } = user;
  if (email) {
    return email.substring(0, 1).toUpperCase();
  }
  return 'U';
}

function removeHash() {
  const loc = window.location;
  if ('pushState' in window.history) {
    window.history.pushState('', document.title, loc.pathname + loc.search);
  } else {
    window.location.hash = '';
  }
}

export type LoginStoreType = {
  user: UserSanitized,
  session: string
};

class LoginStore extends RequestState<LoginStoreType> {
  @observable showFullScreenLoader: boolean = false;

  @observable hubspotUserToken: string | null = null;

  private metaUpdateObserver: IDisposer | null = null;

  constructor() {
    super();

    makeObservable(this);

    if (window.location.hash) {
      // might be a google redirect
      const tokenRAW = window.location.hash.split('&').find(el => el.includes('id_token'));

      if (tokenRAW) {
        const googleToken = tokenRAW.split('id_token=')[1];
        this.login({ googleToken });
        removeHash();
      }
    }

    const { pathname } = RouterStore.location;
    const match = matchPath<{ token: string }>(pathname, { path: '/confirm/:token', exact: true });
    if (match && match.params.token) {
      this.confirmAccount(match.params.token);
    } else {
      const session = getUserToken();
      if (session) {
        this.getUserData(session);

        // if the user is logged in we'll also have hubspotToken
        // so let's not load it now.
        window.hsConversationsSettings = {
          loadImmediately: false,
        };
      }
    }

    reaction(
      () => this.isLoggedIn,
      async isLoggedIn => {
        // if it's no longer logged in, remove token
        if (!isLoggedIn) {
          this.hubspotUserToken = null;
          return;
        }

        // else let's fetch it.
        const data: { token: string } = await fetchie({
          method: 'POST',
          url: `${API}/hubspot/identify`,
        });

        runInAction(() => {
          this.hubspotUserToken = data.token;
        });
      },
    );

    reaction(
      () => this.hubspotUserToken,
      userToken => {
        if (!userToken) return;

        // Token available, load new Chat settings
        window.hsConversationsSettings = {
          // if we have hubspotUserToken, we have email.
          identificationEmail: this.data!.user.email,
          identificationToken: userToken,
        };

        const loadWidgetWhenReady = setInterval(() => {
          if (!window.HubSpotConversations?.widget) return;

          // load widget when HubSpotConversations widget becomes available.
          window.HubSpotConversations.widget.load();
          clearInterval(loadWidgetWhenReady);
        }, 250);
      },
      { fireImmediately: true },
    );
  }

  private addMetaObserver = () => {
    // add an observer on meta, to react to changes.
    assertExists(this.data);

    runInAction(() => {
      // we first patch settings & meta if not present.
      if (this.data && !this.data.user.settings) {
        // if missing settings, initialize with meta.
        this.data.user.settings = {
          meta: {},
        } as UserSettings;
      }
      // else if missing meta just add it.
      if (this.data && !this.data.user.settings.meta) {
        this.data.user.settings.meta = {};
      }
    });

    if (this.metaUpdateObserver) {
      // let's clear old observer if exists.
      this.metaUpdateObserver();
    }

    this.metaUpdateObserver = deepObserve(
      this.data,
      debounce((change, path) => {
        // we observe only to user/settings/meta updates.
        if (!path.startsWith('user/settings/meta')) return;
        if (!this.data || !this.data.user) return;

        // we want to react to changes only for Meta.
        const userSettings = toJSClone(this.data.user.settings);

        // let's update userSetting with new meta.
        this.patchUserData({ settings: userSettings });
      }, 250));
  };

  updateTokenIfNeeded = async (userToken: string) => {
    const decodedToken = parseJWT(userToken);

    // one day has passed since this token was last refreshed, let's refresh.
    if (((new Date().getTime() - decodedToken.iat * 1000) / 86400000) < 1) {
      return userToken;
    }

    const newToken = await fetchie<{ session: string }>({
      url: `${API}/user/session/refresh`,
      method: 'POST',
    }).catch(() => ({
      session: false, // Probably code: "USER_UNAUTHORIZED_EXPIRED_TOKEN"
    }));

    return newToken.session;
  };

  _syncTokenAndUserData = () => {
    const { data } = this;
    if (data) {
      setUserToken(data.session);
      this.addMetaObserver();
    } else {
      deleteUserToken();
    }
  };

  @computed get isLoggedIn(): boolean {
    return !!this.data;
  }

  @computed get isConfirmed(): boolean {
    return !!(this.data && this.data.user.isConfirmed);
  }

  login = flow(function* login(
    this: LoginStore,
    loginData: LoginType,
  ) {

    this.showFullScreenLoader = false;

    if (this.error) {
      resetRequestState(this);
    }

    let whatSocial: 'email' | 'facebook' | 'google' = 'email';

    const data: LoginStoreType | null = yield* maintainRequestState(this, cancelToken => {
      const socialToken = loginData.facebookToken || loginData.googleToken;
      if (socialToken) {
        whatSocial = loginData.facebookToken ? 'facebook' : 'google';
        return fetchie({
          cancelToken,
          url: `${API}/user/social/${whatSocial}`,
          method: 'POST',
          body: {
            token: socialToken,
          },
        });
      }

      const { register = false, ...userData } = loginData;
      if (userData.email && userData.pass) {
        return fetchie({
          cancelToken,
          url: `${API}/user/${register ? 'register' : 'login'}`,
          method: 'POST',
          body: userData,
        });
      }
      throw new Error('Don\'t know how to login with given args');
    }, { cancelPrevious: true });

    if (data) {
      const whatToCall = isSignup(data) ? 'signUp' : 'login';
      eventList.global[whatToCall]({ method: whatSocial });

      if (whatToCall === 'signUp' && window.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }
    }

    this._syncTokenAndUserData();

    return data;
  });

  @action logout = () => {
    this.cancel();
    this.showFullScreenLoader = false;

    // clear reaction to metaObserver, maybe maybe a reactionList in the future.
    if (this.metaUpdateObserver) {
      this.metaUpdateObserver();
    }

    this.data = null;
    this._syncTokenAndUserData();
    RouterStore.push('/');

    if (window.Intercom) {
      // logout from Intercom as well.
      window.Intercom('shutdown');
    }
  };

  handleGoogleCredentialResponse = (response: { clientId: 'string', credential: 'string' }) => {
    this.login({
      googleToken: response.credential,
    });
  };

  getUserData = flow(function* getUserData(
    this: LoginStore,
    session: string | false = getUserToken(),
  ) {
    if (!session) {
      throw new Error('Expected session token to get user data');
    }
    this.showFullScreenLoader = true;

    const newToken = yield this.updateTokenIfNeeded(session);

    yield* maintainRequestState(this, cancelToken => (
      fetchie({
        cancelToken,
        url: `${API}/user`,
        qs: { access_token: newToken },
      }).then(user => ({ session: newToken, user }))
    ), { cancelPrevious: true });

    this.showFullScreenLoader = false;
    this._syncTokenAndUserData();
  });

  confirmAccount = flow(function* confirmAccount(
    this: LoginStore,
    token: string,
  ) {
    this.showFullScreenLoader = true;
    yield* maintainRequestState(this, cancelToken => (
      fetchie({
        cancelToken,
        url: `${API}/user/confirm/${token}`,
        method: 'POST',
      })
    ), { cancelPrevious: true });

    this.showFullScreenLoader = false;
    this._syncTokenAndUserData();
    if (this.data) {
      if (window.location.href.includes('/beta/app/')) {
        RouterStore.push('/');
      }
      AlertStore.showSnackbar({
        message: 'Your email was successfully confirmed',
      });
      eventList.user.emailConfirmation();
    }
  });

  confirmPasswordReset = flow(function* confirmPasswordReset(
    this: LoginStore,
    token: string,
    pass: string,
  ) {
    this.showFullScreenLoader = false;
    yield* maintainRequestState(this, cancelToken => (
      fetchie({
        cancelToken,
        url: `${API}/user/password/reset/${token}`,
        method: 'POST',
        body: { pass },
      })
    ), { cancelPrevious: true });

    this._syncTokenAndUserData();
  });

  resendConfirmEmail = flow(function* resendConfirmEmail(
    requestState = new RequestState(),
  ): any {
    yield* maintainRequestState(requestState, cancelToken => (
      fetchie({
        cancelToken,
        url: `${API}/user/confirm/resend`,
        method: 'POST',
      })
    ), { queue: true });

    AlertStore.showError(requestState.error);
  });

  requestPasswordReset = flow(function* requestPasswordReset(
    email: string,
    requestState = new RequestState(),
  ) {
    yield* maintainRequestState(requestState, cancelToken => (
      fetchie({
        cancelToken,
        url: `${API}/user/password/reset`,
        method: 'POST',
        body: { email },
      })
    ), { queue: true });

    AlertStore.showError(requestState.error);
    if (requestState.data) {
      AlertStore.showSnackbar({
        message: `A password reset email has been sent to ${email}.
        Please check your email and follow the steps to set a new password.`,
        duration: undefined,
      });
    }
  });

  changePassword = flow(function* requestPasswordReset(
    this: LoginStore,
    newPassword: string,
    oldPassword: Nullable<string> = null,
    requestState: RequestState<LoginStoreType> = new RequestState(),
  ) {
    const data = yield* maintainRequestState(requestState, cancelToken => (
      fetchie({
        cancelToken,
        url: `${API}/user/password/change`,
        method: 'POST',
        body: {
          newPassword,
          oldPassword,
        },
      })
    ), { queue: true });

    if (data) {
      setUserToken(data.session);
      if (this.data) {
        this.data = {
          session: data.session,
          user: {
            ...this.data.user,
            hasPassword: true,
          },
        };
      }
    }
  });

  patchUserData = flow(function* patchUserData(
    this: LoginStore,
    patch: Partial<UserSanitized>,
    requestState: RequestState<UserSanitized> = new RequestState(),
  ): any {
    const data = yield* maintainRequestState(requestState, cancelToken => (
      fetchie({
        cancelToken,
        url: `${API}/user`,
        method: 'PATCH',
        body: patch,
      })
    ), { queue: true });

    AlertStore.showError(requestState.error);
    if (data && this.data) {
      this.data.user = data;
    }
  });

  // FOR DEBUGGING PURPOSES ONLY:

  async _resetProjects() {
    // Delete all projects
    const projectList: {
      project: ProjectData,
      thumbnail: string,
    }[] = await fetchie({
      url: `${API}/project/list`,
      method: 'GET',
      qs: { limit: MAX_SAFE_INTEGER },
    });

    await Promise.all(projectList.map(({ project: { projectID } }) => (
      fetchie({ url: `${API}/project/${projectID}`, method: 'DELETE' })
    )));
  }

  async _resetMedia() {
    // Delete all media
    const mediaList: MediaData[] = await fetchie({
      url: `${API}/media/list`,
      method: 'GET',
      qs: { limit: MAX_SAFE_INTEGER },
    });

    await Promise.all(mediaList.map(({ mediaID }) => (
      fetchie({ url: `${API}/media/${mediaID}`, method: 'DELETE' })
    )));
  }

  async _reset() {
    await this._resetProjects();
    await this._resetMedia();
  }
}

const loginStore = new LoginStore();

declare global {
  interface Window {
    LoginStore: LoginStore,
  }
}

window.LoginStore = loginStore;

setExpiredTokenHandler(() => {
  loginStore.logout();
});

export default loginStore;
