import React from 'react';
import { observer } from 'mobx-react';

import { Paper, Tabs, Tab } from '@material-ui/core';
import LoginComponent from './aive/src/components/login/LoginComponent';
import { SimpleModal } from './aive/src/components/project/SimpleModal';
import type { InputChangeType } from './aive/src/components/login/LoginPage';

import ConfirmationStore from './stores/ConfirmationStore';
import { RequestState } from './aive/src/functions/requestState';

import classes from './LoginModal.module.scss';

function LoginModal() {
  const [email, setEmail] = React.useState<string>(ConfirmationStore.requestEmail ?? '');
  const [password, setPassword] = React.useState<string>('');
  const [isPassVisible, setPassVisible] = React.useState<boolean>(false);
  const [showForgotPass, setShowForgotPass] = React.useState<boolean>(false);

  const recoveryState = React.useRef(new RequestState());

  const handleSetEmail = React.useCallback((e: InputChangeType) => {
    setEmail(e.target.value);
  }, [setEmail]);

  const handleSetPassword = React.useCallback((e: InputChangeType) => {
    setPassword(e.target.value);
  }, [setPassword]);

  const handleSetPassVisible = React.useCallback((e: React.MouseEvent<any>) => {
    setPassVisible(!isPassVisible);
  }, [setPassVisible, isPassVisible]);

  const handleShowForgotPass = React.useCallback(() => {
    setShowForgotPass(true);
  }, [setShowForgotPass]);

  const handleHideForgotPass = React.useCallback(() => {
    setShowForgotPass(false);
  }, [setShowForgotPass]);

  return (
    <SimpleModal
      open={ConfirmationStore.showLogin}
      onClose={ConfirmationStore.handleHideLogin}
    >
      <Paper className={classes.root}>
        <Tabs
          value="login"
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            value="login"
            label="Log In"
            onClick={handleHideForgotPass}
          />
        </Tabs>
        <LoginComponent
          className={classes.loginContainer}
          isActive
          showGoogle

          recoveryState={recoveryState.current}

          email={email}
          handleChangeEmail={handleSetEmail}

          password={password}
          handleChangePassword={handleSetPassword}

          showForgotPass={showForgotPass}
          handleForgotClick={handleShowForgotPass}
          handleLoginClick={handleHideForgotPass}

          showPassword={isPassVisible}
          handleShowPassword={handleSetPassVisible}
        />
      </Paper>
    </SimpleModal>
  );
}

export default React.memo(observer(LoginModal));
