/**
 Copyright Findie 2021
 */
import {
  action,
  computed,
  IObservableArray,
  observable,
  reaction,
  makeObservable,
} from 'mobx';
import { API } from '../paths';

export type NetworkStateStatus = 'online' | 'offline' | 'failing' | 'recovering' | 'unknown';

const CHECK_TIMEOUT = 3000;
const HISTORY_SIZE = 5;

class NetworkStateStore {
  @observable private history: {
    time: number, isOnline: boolean
  }[] = [{ time: Date.now(), isOnline: true }];

  @observable private navigator_onLine: boolean = true;

  @computed get state(): NetworkStateStatus {
    const h = this.history;
    if (h.length && h.every(x => x.isOnline)) return 'online';
    if (h.length && h.every(x => !x.isOnline)) return 'offline';

    if (h.length && h[h.length - 1].isOnline) return 'recovering';
    if (h.length && !h[h.length - 1].isOnline) return 'failing';

    return 'unknown';
  }

  private onlineCheckTimer: NodeJS.Timeout | null = null;

  constructor() {
    makeObservable(this);
    window.addEventListener('online', action(() => {
      this.navigator_onLine = true;
      this.reportOnline();
    }));
    window.addEventListener('offline', action(() => {
      this.navigator_onLine = false;
      (this.history as IObservableArray).clear();
      this.reportOffline();
    }));

    reaction(() => this.state, () => {
      if (this.state === 'online') {

        if (this.onlineCheckTimer) {
          clearInterval(this.onlineCheckTimer);
          this.onlineCheckTimer = null;
        }

      } else {
        // eslint-disable-next-line no-lonely-if
        if (!this.onlineCheckTimer) {
          this.onlineCheckTimer = setInterval(this.onlineCheck, CHECK_TIMEOUT);
        }
      }
    });
  }

  reportOffline = () => this.report(false);

  reportOnline = () => this.report(true);

  @action
  private report(online: boolean) {
    this.history.push({ time: Date.now(), isOnline: online });
    if (this.history.length > HISTORY_SIZE) this.history.splice(0, 1);
  }

  private onlineCheck = async () => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), CHECK_TIMEOUT);

    try {
      await fetch(`${API}/echo`, {
        method: 'get',
        signal: controller.signal,
      });
      clearTimeout(id);
      this.reportOnline();
    } catch (e) {
      this.reportOffline();
    }
  };
}

export const NetworkState = new NetworkStateStore();

// @ts-ignore
window.NetworkState = NetworkState;
