/**
 Copyright Findie 2021
 */
import { action, makeObservable, observable } from 'mobx';
import { assertExists } from '../functions/asserts';
import { AnyAPIError, GenericAPIError } from '../functions/errors';
import { fetchie } from '../functions/fetchie';
import { API } from '../paths';

export type MFAChallengeTypes = 'totp'; // more TBD
export type MFAChallengeScope = 'login' | 'test' | string; // more TBD

export class MFAChallengeStoreClass {

  @observable challenge: null | {
    challenge: MFAChallengeTypes,
    scope: MFAChallengeScope,
    data: string
  } = null;

  private challengeCallbacks: null | {
    res: (data: any) => void,
    rej: (error: Error | AnyAPIError | unknown) => void
  } = null;

  constructor() {
    makeObservable(this);
  }

  @action
  challengeRequested = <T>(challenge: MFAChallengeStoreClass['challenge']) => {
    this.setChallenge(challenge);

    return new Promise<T>((res, rej) => {
      this.challengeCallbacks = { res, rej };
    });
  };

  @action
  private setChallenge = (challenge: MFAChallengeStoreClass['challenge']) => this.challenge = challenge;

  @action
  private clearChallenge = () => this.challenge = null;

  cancelChallenge = (error: Error = new Error('Cancelled code input')) => {
    this.clearChallenge();

    const e: GenericAPIError = {
      code: '',
      error: '',
      statusCode: 0,
      message: error.message,
    };

    this.challengeCallbacks?.rej(e);
    this.challengeCallbacks = null;
  };

  resolveChallenge = async <T>(code: string) => {
    assertExists(this.challenge, 'no challenge to solve');
    const challengeURL = `${API}/user/challenge/${this.challenge.challenge}/${this.challenge.scope}`;

    const data = await fetchie<T>({
      url: challengeURL,
      method: 'POST',
      body: {
        challenge: this.challenge.data,
        code,
      },
    });

    this.clearChallenge();
    this.challengeCallbacks?.res(data);
    this.challengeCallbacks = null;

    return data;
  };
}

export const MFAChallengeStore = new MFAChallengeStoreClass();

// @ts-ignore
window.MFAChallengeStore = MFAChallengeStore;
