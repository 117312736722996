import * as Sentry from '@sentry/browser';
import { observable, makeObservable } from 'mobx';
import { createCancelToken, CancelToken } from './cancelPromise';
import { AnyAPIError } from './errors';
import { CustomError, replaceObjectKeepRefs } from './helpers';

const nop = () => {};

export class RequestState<T, E extends AnyAPIError = AnyAPIError> {
  @observable
  loading: boolean = false;

  @observable
  downloadProgress: {
    loaded: Nullable<number>,
    total: Nullable<number>,
  } = {
      loaded: null,
      total: null,
    };

  @observable
  data: Maybe<T> = null;

  @observable
  error: Maybe<E> = null;

  @observable
  timestamp: Nullable<number> = null;

  @observable
  promise: Promise<Maybe<T>> = Promise.resolve<Maybe<T>>(undefined);

  @observable
  cancel: typeof nop = nop;

  constructor() {
    makeObservable(this);
  }
}

export function getRequestState<K, T>(map: Map<K, RequestState<T>>, key: K): RequestState<T> {
  let state = map.get(key);
  if (state) { return state; }
  state = new RequestState();
  map.set(key, state);
  return state;
}

type Options = {
  cancelPrevious?: boolean,
  queue?: boolean,
  discardData?: boolean,
  keepDataOnError?: boolean,
  cancelTokenPair?: { cancel: typeof nop, token: CancelToken<any> },
  replaceData?: boolean
};

// todo: Check why on an error, maintainRequestState no longer runs.
export function* maintainRequestState<T>(
  state: RequestState<T>,
  runRequest: (cancelToken: CancelToken<any>) => (Promise<T> | T),
  options?: Options,
) {
  const cancelPrevious = options && options.cancelPrevious;
  const queue = cancelPrevious || (options && options.queue);
  const discardData = options && options.discardData;
  const keepDataOnError = discardData || (options && options.keepDataOnError);

  if (state.loading && !queue) {
    throw new Error('A request is already pending');
  }

  if (cancelPrevious && state.cancel) {
    state.cancel();
  }

  state.loading = true;
  state.error = null;

  let cancelled = false;
  const { cancel, token } = (options && options.cancelTokenPair) || createCancelToken();
  // todo: if the Promise Errors - then the Promise gets rejected and the state.promise.catch
  // will no longer run the next then
  const promise = (queue ? state.promise.catch<T>() : Promise.resolve<Nullable<T>>(null))
    .then<T>(() => Promise.race<T>([
    token.promise.then(() => { cancelled = true; }) as Promise<T>,
    runRequest(token),
  ]));
  state.promise = promise;
  state.cancel = cancel;

  let data: T;
  try {
    data = yield promise;
  } catch (error) {
    state.loading = false;
    state.error = error;
    // 412 is usually smart crop data for media
    // which most of the time lacks.
    if (error.statusCode !== 412) {
      Sentry.captureException(new CustomError('maintainRequestState error', {
        initialError: error,
      }));
    }
    if (!keepDataOnError) { state.data = null; }
    state.timestamp = new Date().getTime();
    state.cancel = nop;
    // this should fix the above todos
    state.promise = Promise.resolve(null);
    return null;
  }

  // If a new request started synchronously before knowing the previous one
  // got cancelled don't overwrite state
  if (!cancelled || (state.cancel === cancel)) {
    state.loading = false;
    state.cancel = nop;
  }
  if (!cancelled) {
    if (!discardData) {
      // the old way was to replace the data
      if (
        state.data && typeof state.data === 'object'
        && data && typeof data === 'object' && !options?.replaceData
      ) {
        // let's patch to keep as many refs as possible.
        replaceObjectKeepRefs(state.data, data);
      } else {
        // patching is not possible, just replace.
        state.data = data;
      }
    }
    state.timestamp = new Date().getTime();
  } else {
    // If the request got cancelled, yield indefinitely
    yield new Promise(() => {});
  }
  return data;
}

export function manuallySetRequestState<T, RS extends RequestState<T> = RequestState<T>>(
  state: RS,
  data?: Nullable<T>,
  timestamp: Date = new Date(),
) {
  state.cancel();
  state.loading = false;
  state.data = data;
  state.error = null;
  state.timestamp = timestamp.getTime();
  state.promise = Promise.resolve(data);
  state.cancel = nop;

  return state;
}

export function resetRequestState<T>(state: RequestState<T>) {
  manuallySetRequestState<T>(state, null, undefined);
}
