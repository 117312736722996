import querystring from 'querystringify';
import { MFAChallengeStore, MFAChallengeStoreClass } from '../stores/MFAChallengeStore';
import { NetworkState } from '../stores/NetworkStateStore';
import { assertExists } from './asserts';
import { CancelToken, ignorePromiseOnCancel } from './cancelPromise';
import type { GenericAPIError } from './errors';
import { delay } from './helpers';

import { assert } from './modules/hoek';

let accessTokenCache: string | null;

export function getToken(): false | string {
  if (accessTokenCache === null) {
    return false;
  }

  if (!accessTokenCache) {
    accessTokenCache = localStorage.getItem('access_token');
    if (accessTokenCache) { return accessTokenCache; }

    accessTokenCache = null;
    return false;
  }

  return accessTokenCache;
}

export function setToken(token: string) {
  if (!token) {
    return;
  }

  accessTokenCache = token;
  localStorage.setItem('access_token', token);
}

export function deleteToken() {
  accessTokenCache = null;
  localStorage.removeItem('access_token');
}

export function parseJWT(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = decodeURIComponent(atob(base64Url).split('').map(
    c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`,
  ).join(''));

  return JSON.parse(base64);
}

window.parseJWT = parseJWT;

declare global {
  interface Window {
    parseJWT: typeof parseJWT,
    getToken: typeof getToken,
    setToken: typeof setToken,
  }
}

function parseResponse<T>(response: Response): Promise<T> {
  const contentType = (response.headers.get('Content-Type') || '').split(';')[0];
  switch (contentType) {
  case 'application/json':
    return response.json() as Promise<T>;
  default:
    throw new Error('Cannot parse response');
  }
}

let expiredTokenHandler = () => {};
export const setExpiredTokenHandler = (f: () => void) => { expiredTokenHandler = f; };

type Options = {
  url: string,
  method?: 'GET' | 'HEAD' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  body?: Object,
  qs?: Object,
  cancelToken?: CancelToken<any>,
  retryCount?: number,
  maxRateLimitBackoff?: number,
} & ({
  json?: false,
  blob?: true,
} | {
  json?: true,
  blob?: false,
});

type GenericError = GenericAPIError & { success: undefined };
type Challenge = MFAChallengeStoreClass['challenge'] & { success: undefined };

interface FetchieRequestInit extends RequestInit {
  headers: {
    Accept?: string,
    Authorization?: string,
    'content-type'?: string,
  } & RequestInit['headers']
}

export async function fetchie<T>({
  method = 'GET', url,
  body = {}, qs = {},
  json = true, blob = false,
  cancelToken, retryCount,
  maxRateLimitBackoff = 12000,
}: Options): Promise<T> {
  assert((!json || !blob) && (json || blob), 'Pass exactly one of blob or json at the same time');

  let abortSignal;
  let abortController: Nullable<AbortController> = null;
  if (cancelToken && window.AbortController) {
    abortController = new window.AbortController();
    abortSignal = abortController.signal;
  }

  const accessToken = window.getToken();
  const opts: FetchieRequestInit = {
    method,
    headers: {},
    mode: 'cors',
    signal: abortSignal,
    body: undefined,
  };

  opts.headers.Accept = 'application/json';

  if (accessToken) {
    opts.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (~['POST', 'PATCH', 'PUT', 'DELETE'].indexOf(method)) {
    if (body instanceof FormData) {
      opts.body = body;
    } else {
      opts.headers['content-type'] = 'application/json';
      opts.body = JSON.stringify(body);
    }
  }

  // eslint-disable-next-line
  url += querystring.stringify(
    qs,
    // if url has "?" (query strings) already, append via "&", else create with "?"
    ~url.indexOf('?') ? '&' : '?',
  );

  let retriesLeft: number;
  if (typeof retryCount === 'number') {
    retriesLeft = retryCount;
  } else {
    retriesLeft = method === 'GET' ? 3 : 1;
  }

  let tryFetch: () => Promise<Response> = async () => { return {} as Response; };

  function retryIfNeeded(err: {
    code: string,
    message: string,
  }) {
    if (typeof err === 'object' && (
      err.code === 'USER_UNAUTHORIZED_NO_TOKEN' || err.code === 'USER_UNAUTHORIZED_EXPIRED_TOKEN'
    )) {
      expiredTokenHandler();
      throw new Error(err.message);
    }
    retriesLeft -= 1;
    if (retriesLeft <= 0) { throw new Error(err.message); }
    return tryFetch();
  }

  let rateLimitRetriesLeft = 3;
  function waitForRateLimit(response: Response) {
    if (response.status === 429
      && response.headers.get('X-Rate-Limit-Remaining') === '0'
      && rateLimitRetriesLeft > 0
    ) {
      rateLimitRetriesLeft -= 1;

      const reset = response.headers.get('X-Rate-Limit-Reset');
      if (!reset) { return response; }
      const resetTime = parseInt(reset, 10);
      if (Number.isNaN(resetTime)) { return response; }
      const backoff = Math.max(0, resetTime * 1000 - Date.now() + 100 + Math.random() * 1000);
      if (backoff > maxRateLimitBackoff) { return response; }

      return delay(backoff, cancelToken).then(tryFetch);
    }
    return response;
  }

  tryFetch = (): Promise<Response> => (
    ignorePromiseOnCancel(fetch(url, opts), cancelToken)
      .then(waitForRateLimit, retryIfNeeded)
  );

  const response = tryFetch();

  if (abortController && cancelToken) {
    cancelToken.promise.then(() => {
      assertExists(abortController);
      abortController.abort();
    });
  }

  response.catch(error => {
    console.error('[fetchie] error', error);
    NetworkState.reportOffline();
    return error;
  });

  const resp = await response;

  NetworkState.reportOnline();

  if (json) {
    const ret = await parseResponse<
    /**/ { success: true, data: T, warning?: string[] } |
    /**/ GenericError |
    /**/ Challenge
    >(resp);

    if ('warning' in ret && ret.warning?.length) {
      const { warning } = ret;

      setTimeout(() => {
        // eslint-disable-next-line no-console
        console.log('%cAPI Route Warning!', 'color:red;font-size:48px');
        // eslint-disable-next-line no-console
        console.log(`%c${method}: ${url} said:`, 'color:red;font-size:18px');
        // eslint-disable-next-line no-console
        console.log(`%c${warning.join('\n')}`, 'color:red;font-size:18px');
      }, 1000);
    }

    if (ret.success) {
      return ret.data;
    }

    if ('challenge' in ret && ret.challenge) {
      return MFAChallengeStore.challengeRequested(ret);
    }

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw ret;
  }

  return await resp.blob() as unknown as Promise<T>;
}

window.fetchie = fetchie;
window.getToken = getToken;
window.setToken = setToken;

export default fetchie;
