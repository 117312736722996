import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/';
import { observer } from 'mobx-react';

import AlertStore from '../stores/AlertStore';

const styles = {
  warn: {
    backgroundColor: '#fffa2b',
    color: '#000000',
  },
  error: {
    backgroundColor: '#eb2b2b',
    color: '#FFF',
  },
  info: {
    backgroundColor: '#41c00e',
  },
};

type Props = {
  classes: Record<'warn' | 'error' | 'info', string>
};

@observer
class Alert extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    const {
      vertical,
      horizontal,
      isVisible,
      message,
      type,
      duration,
    } = AlertStore;

    const contentProps = {
      classes: {
        root: classes[type],
      },
    };

    return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={isVisible}
        onClose={AlertStore.hideSnackBar}
        ContentProps={contentProps}
        autoHideDuration={duration}
        message={message}
      />
    );
  }
}

export default withStyles(styles)(Alert);
