import React from 'react';
import { Router, Route, Switch } from 'react-router';
import { StylesProvider, createGenerateClassName, jssPreset } from '@material-ui/styles';
import { create, Jss } from 'jss';

import { CssBaseline } from '@material-ui/core';
import ThemeProvider from './aive/src/components/layout/ThemeProvider';
import { history } from './RouterStoreHomePage';
import Header from './Header';
import HomePage from './HomePage';
import TinyVid from './TinyVid';
import Alert from './aive/src/components/Alert';
import ExitWarning from './aive/src/components/atoms/ExitWarning';

const generateClassName = createGenerateClassName({
  productionPrefix: 'kamua',
});

// @ts-ignore - apparently ts issues when upgrade to last material-ui
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: 'jss-insertion-point',
}) as Jss;

function App() {
  return (
    // @ts-ignore - jss not compatible apparently.
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <ThemeProvider>
        <Router history={history}>
          <CssBaseline />
          <React.StrictMode>
            <Header />
            <Switch>
              <Route exact path="/tinyvid" component={TinyVid} />
              <Route component={HomePage} />
            </Switch>
            <Alert key="alert" />
          </React.StrictMode>
        </Router>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
