import React from 'react';
import { hydrate, render } from 'react-dom';

import * as Sentry from '@sentry/browser';
import { toJS, configure } from 'mobx';
import { Integrations } from '@sentry/tracing';

import { fetchie } from './aive/src/functions/fetchie';

import { unregister } from './serviceWorker';
import 'mobx-react-lite/batchingForReactDom';

import App from './App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://9c357575d80a43f89bf010d675374000@sentry.io/1499204',
    integrations: [
      new Integrations.BrowserTracing(),
    ],
  });
}

function GA(...args: string[]) {
  const eventCategory = args[2];
  const eventAction = args[3];
  const eventLabel = args[4] || '';
  const eventValue = args[5] || '';

  window.sendEvent({
    event: 'analyticsEvent',
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
  });
}

type EventData = {
  event: string,
  label?: string,
  value?: number,
} | {
  event: 'analyticsEvent',
  label: never,
  eventCategory: string,
  eventAction: string,
  eventLabel: string,
  eventValue: string,
} | {
  event: 'userAlert',
  eventAction: string,
  label: string,
};

type SentryType = {
  captureException: typeof Sentry.captureException
};

configure({
  enforceActions: 'observed',
});

function sendEvent(data: EventData) {
  if (!window.dataLayer) return;
  window.dataLayer.push(data);
}

window.toJS = toJS;
window.ga = GA;
window.sendEvent = sendEvent;
window.isMac = !!~navigator.platform.toUpperCase().indexOf('MAC');

interface HubSpotOn {
  (event: 'unreadConversationCountChanged', callback: (payload: { unreadCount: number }) => void): void
  (event: 'contactAssociated', callback: (payload: { message: string }) => void): void,
  (event: 'conversationStarted', callback: (payload: { conversationId: number }) => void): void
}

interface Amplitude {
  getInstance: () => {
    logEvent(action: string, properties?: any): void;
    setUserId(userId: string): void;
    setUserProperties(properties: any): void;
  }
}

declare global {

  interface Window {
    hubspot: any;
    google: any;
    gapi: any;
    sendEvent: (data: EventData) => void,
    dataLayer: EventData[],
    toJS: typeof toJS,
    ga: typeof GA,
    Intercom: (args0: string) => void,
    fetchie: typeof fetchie,
    isMac: boolean,
    FB: facebook.FacebookStatic,
    Sentry: SentryType,
    amplitude: Amplitude,
    gtag: any,
    _hsq: {
      push: (arg0: [
        'trackEvent' | 'identify' | 'trackPageView',
        { [key: string]: string | number | boolean },
      ]
      ) => void,
    }
  }
}

unregister();

function removeServerSideCSS() {
  Array.from(document.querySelectorAll('[data-jss]'))
    .forEach(elem => elem.parentNode?.removeChild(elem));
}

const rootElement = document.getElementById('root');
if (rootElement?.hasChildNodes()) {
  removeServerSideCSS();

  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
