import React, { useCallback } from 'react';
import Close from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import { IconButton } from '@material-ui/core';
import classes from './SimpleModal.module.scss';

type Props = {
  open: boolean,
  onClose: () => void,
  hideCloseButton?: boolean,
  children: any,
};

export const SimpleModal = React.memo((props: Props) => {
  const { children, open, onClose, hideCloseButton } = props;

  const handleOnClose = useCallback(() => onClose(), [onClose]);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleOnClose}
    >
      <div className={classes.modalContainer}>
        {children}
        {
          !hideCloseButton
          && (
            <IconButton className={classes.close} onClick={handleOnClose}>
              <Close />
            </IconButton>
          )
        }
      </div>
    </Modal>
  );
});
