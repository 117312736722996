import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

import { colorPrimary, colorSecondary, colorCharcoalGray, colorWhite } from '../../functions/colors';

export const KamuaThemeOptions: ThemeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: colorPrimary,
    },
    secondary: {
      main: colorSecondary,
    },
  },
  typography: {
    fontFamily: 'Questrial, sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.Mui-disabled': {
          opacity: 0.5,
        },
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
    },
    MuiSwitch: {
      track: {
        backgroundColor: `${colorWhite} !important`,
      },
    },
    MuiMenu: {
      paper: {
        background: colorCharcoalGray,
      },
    },
    MuiDialog: {
      paper: {
        background: colorCharcoalGray,
      },
      paperFullScreen: {
        background: '#343434',
      },
    },
    MuiPopover: {
      paper: {
        background: colorCharcoalGray,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '42px',
      },
    },
    MuiListItemText: {
      primary: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 4,
      },
    },
  },
};

const theme = createMuiTheme(KamuaThemeOptions);

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  );
}
