import React from 'react';
import { observable, action, observe, makeObservable } from 'mobx';

import LoginStore from '../aive/src/stores/LoginStore';
import AlertStore from '../aive/src/stores/AlertStore';
import { errorIsValidationError } from '../aive/src/functions/errors';
import { eventList } from '../aive/src/tracking/events';

class ConfirmationStore {
  @observable requestEmail: string | null = null;

  @observable showMoreInfoDialog: boolean = false;

  @observable showLogin: boolean = false;

  constructor() {
    makeObservable(this);

    observe(
      LoginStore,
      'isLoggedIn',
      change => {
        if (!change.oldValue && change.newValue && this.showLogin) {
          this.handleHideLogin();
        }
      },
    );
  }

  @action
  updateEmail = (e: React.ChangeEvent<{ value: string }>) => {
    this.requestEmail = e.target.value;
  };

  @action
  onCloseMoreInfoDialog = () => {
    this.showMoreInfoDialog = false;
  };

  @action
  startRequest = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // if startRequest happens, form is already valid.
    this.showMoreInfoDialog = true;
    eventList.homepage.clickedHPTryNow();
  };

  @action
  handleShowLogin = () => {
    this.showLogin = true;
    eventList.homepage.clickedLogin();
  };

  @action
  handleHideLogin = () => {
    this.showLogin = false;
  };

  @action resetAndCloseRequestDialog = () => {
    this.showMoreInfoDialog = false;
    this.requestEmail = '';
  };

  @action
  createAccount = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const userData : { [key: string]: string } = {};

    const data = new FormData(e.currentTarget);
    data.forEach((val, key) => {
      if (typeof val !== 'string') return;

      userData[key] = val;
    });

    eventList.homepage.moreDetailsSubmit();

    const newUserData = await LoginStore.login({
      register: true,
      email: userData.email,
      pass: userData.pass,
      redirect: 'https://kamua.com',
      // @ts-ignore
      details: {
        name: {
          first: userData.firstName,
          last: userData.lastName,
        },
      },
    });

    if (newUserData) {
      this.resetAndCloseRequestDialog();
    } else if (LoginStore.error && !errorIsValidationError(LoginStore.error) && LoginStore.error.code === 'USER_ALREADY_REGISTERED') {
      AlertStore.showError('User already exists. Please log in.');
      this.handleShowLogin();
      this.onCloseMoreInfoDialog();
    } else {
      AlertStore.showError(LoginStore.error);
    }
  };
}

const initiatedConfirmationStore = new ConfirmationStore();

declare global {
  interface Window {
    ConfirmationStore: ConfirmationStore,
  }
}

window.ConfirmationStore = initiatedConfirmationStore;

export default initiatedConfirmationStore;
