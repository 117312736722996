export const errorStrings = {
  // project
  NOT_PROJECT_OWNER: 'You are not the owner of the project',
  PROJECT_RENDER_TOO_MANY_ITEMS: 'You are trying to render too many items',

  // job
  JOB_EMPTY: 'The job is empty',
  JOB_INCOMPLETE: 'The job is incomplete',
  NOT_JOB_OWNER: 'This project is not the owner of the job',

  // user
  USER_ALREADY_REGISTERED: 'User already registered',
  CANNOT_FIND_USER: 'Cannot find user by given criteria',
  USER_NOT_FOUND: 'There is no user with this email',
  USER_BAD_PASSWORD: 'Incorrect password',
  USER_NO_PASSWORD: 'Please log in with social media account',
  USER_BAD_CONFIRMATION_TOKEN: 'Incorrect email confirmation token',
  USER_BAD_PASSWORD_RESET_TOKEN: 'Incorrect password reset token',
  USER_NOT_CONFIRMED: 'Please confirm your e-mail address first',
  USER_UNAUTHORIZED_NO_TOKEN: 'You are not logged in',
  USER_UNAUTHORIZED_EXPIRED_TOKEN: 'Your login session expired',
  USER_LOGIN_NOT_ALLOWED: 'Login Unavailable - See Banner Above',
  DISABLED_FUNCTIONALITY: 'New user accounts are not permitted',

  // external
  FACEBOOK_API_ERROR: 'Facebook API error',

  // translate
  EXTERNAL_INVALID_URL: 'Link appears to be invalid, please check it is a valid video URL',
  EXTERNAL_UNKNOWN: 'An unknown error has occurred, please try again later',
  EXTERNAL_NOT_WHITELISTED: 'This website is not currently supported. To request we support it, please send us a support message',
  EXTERNAL_UNSUPPORTED_URL: 'This website is not currently supported. To request we support it, please send us a support message',
  EXTERNAL_MEDIA_NOT_FOUND: 'We were unable to locate a video from this link. Ensure it is not set to private',
  EXTERNAL_PROTECTED: 'This link appears password/email protected. Please remove protections to proceed',
  EXTERNAL_GEO_LOCKED: 'We cannot link this Media due to a geographical restriction on the Media source',
  EXTERNAL_SLOW_DOWN: 'We cannot link this Media at present. Please wait a few minutes and then try again',
  EXTERNAL_LIVE_FORBIDDEN: 'We currently do not support live videos. Please try again after the live has finished',

  // media
  CANNOT_FIND_MEDIA: 'Cannot find Media by given criteria',
  MEDIA_NOT_FOUND: 'Media not found',
  CANNOT_FIND_MEDIA_VARIANT: 'Cannot find Media variant by given criteria',
  NOT_MEDIA_OWNER: 'You are not the owner of the Media',
  MEDIA_NOT_READY: 'Media is not ready',
  MEDIA_SOURCE_MISMATCH_UPLOAD: 'You are trying to upload a file for a Media of external source',
  MEDIA_INVALID: 'Provided Media file is invalid',

  // quota
  QUOTA_APP_COUNT: 'Quota for app count depleted',
  QUOTA_NEED_CREDITS: 'Not enough credits left on your subscription',
  QUOTA_NEED_STORAGE: 'Not enough storage left on your subscription',
  QUOTA_NEED_SECONDS_IN: 'Not enough ingest time left on your subscription',
  QUOTA_NEED_SECONDS_OUT: 'Not enough render time left on your subscription',

  // server
  TOO_MANY_REQUESTS: 'Oops, too many requests!',
  UNKNOWN_ERROR: 'Unknown error',
  NOT_IMPLEMENTED: 'Not implemented',

  // apps
  TOKEN_EXPIRED: 'App token has expired',
  TOKEN_INVALID: 'App token is invalid',
  NOT_APP_OWNER: 'You are not the owner of the app',
  APP_NOT_FOUND: 'App not found',

  // chargebee
  CHARGEBEE_GENERIC_ERROR: 'Payment system error',

  // OTP
  USER_BAD_2FA_CODE: 'Bad code, please try again!',
  USER_TOTP_ALREADY_SETUP: 'OTP Already set up',
  OTP_CHALLENGE_EXPIRED: 'Challenge expired, please try again!',
};

export type KnownAPIErrorCode = keyof typeof errorStrings;

export const errorCodes: Set<KnownAPIErrorCode> = new Set(Object.keys(errorStrings));

export function errorCodeIsKnown(code: string): code is KnownAPIErrorCode {
  return errorCodes.has(code as KnownAPIErrorCode);
}
export function errorIsValidationError(error: AnyAPIError): error is ValidationAPIError {
  return !!(error as ValidationAPIError).validation || !(error as GenericAPIError).code;
}

export type GenericAPIError = {
  code: KnownAPIErrorCode | string,
  error: string
  message: string
  statusCode: number
};

export type ValidationAPIError<V = unknown> = {
  error: string
  message: string
  statusCode: number
  validation: V
};

export type AnyAPIError = GenericAPIError | ValidationAPIError;

export function formatError(error?: AnyAPIError): string {
  if (!error) {
    return '';
  }
  if (typeof error === 'string') {
    return error;
  }

  if (!errorIsValidationError(error) && errorCodeIsKnown(error.code)) {
    return errorStrings[error.code];
  }
  return error.message || errorStrings.UNKNOWN_ERROR;
}

export function errorHasCode(error: AnyAPIError, code: KnownAPIErrorCode): boolean {
  if (!error) {
    return false;
  }
  if (typeof error !== 'object') {
    return false;
  }

  if (errorIsValidationError(error)) {
    return false;
  }

  return error.code === code;
}
