import React, { ChangeEvent } from 'react';
import { IconButton, Slider } from '@material-ui/core';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PauseIcon from '@material-ui/icons/Pause';
import PlayIcon from '@material-ui/icons/PlayArrow';

import classes from './VideoEmbedWithControls.module.scss';

type Props = {
  video: {
    [key: number]: string
  },
  poster: string,
};

function VideoEmbedWithControls(props: Props) {
  const { video, poster } = props;

  const [mainVideoRef, setMainVideoRef] = React.useState<HTMLVideoElement | null>(null);
  const [isVideoMuted, setVideoMuted] = React.useState(true);
  const [isVideoPaused, setVideoPaused] = React.useState<boolean | null>(null);
  const [progress, setProgress] = React.useState(0);
  const [videoSRC, setVideoSRC] = React.useState('');

  // we don't want to resume once in view if the user manually paused.
  const manuallyPaused = React.useRef(false);
  const timeoutToHidePlaybutton = React.useRef<null | NodeJS.Timeout>(null);

  const onContainerLoad = React.useCallback((ref: HTMLDivElement) => {
    if (!ref) return;

    const size = ref.getBoundingClientRect().width;
    const dpi = window.devicePixelRatio || 1.0;

    const neededSize = size * dpi;
    let src = '';
    let lastRes: number | undefined;

    Object.keys(video).map(parseFloat).sort((a, b) => a - b).some(res => {
      lastRes = res;
      if (neededSize < res) {
        src = video[res];
        return true;
      }
      return false;
    });

    if (!src && lastRes) {
      src = video[lastRes];
    }

    setVideoSRC(src);
  }, []);

  const togglePlayPause = React.useCallback(() => {
    if (!mainVideoRef || timeoutToHidePlaybutton.current) return;

    const newState = !mainVideoRef.paused;

    setVideoPaused(newState);
    if (mainVideoRef.paused) {
      manuallyPaused.current = false;
      mainVideoRef.play();
    } else {
      manuallyPaused.current = true;
      mainVideoRef.pause();
    }

    timeoutToHidePlaybutton.current = setTimeout(() => {
      timeoutToHidePlaybutton.current = null;
      setVideoPaused(null);
    }, 500);
  }, [mainVideoRef]);

  const toggleMainVideoSound = React.useCallback(() => {
    const vid = mainVideoRef;

    if (!vid) return;

    const newValue = !vid.muted;

    vid.muted = newValue;
    setVideoMuted(newValue);
  }, [mainVideoRef, setVideoMuted]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!mainVideoRef) return;
      setProgress(mainVideoRef?.currentTime);
    }, 16);

    return () => {
      clearInterval(interval);
    };
  }, [mainVideoRef, setProgress]);

  React.useEffect(() => {
    if (!mainVideoRef) return;

    const observer = new IntersectionObserver(([entry]) => {

      if (entry.intersectionRatio < 0.7 && !mainVideoRef.paused) {
        mainVideoRef.pause();
      } else if (entry.intersectionRatio > 0.7 && mainVideoRef.paused && !manuallyPaused.current) {
        mainVideoRef.play().catch(console.log);
      }
    }, { threshold: 0.7 }); // 70% of the video visible.

    observer.observe(mainVideoRef);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [mainVideoRef]);

  const sliderOnChange = React.useCallback(
    (event: ChangeEvent<{}>, value: number | number[]) => {
      if (!mainVideoRef) return;

      mainVideoRef.currentTime = value as number;
    },
    [mainVideoRef],
  );

  const sliderLabelFormat = React.useCallback(
    (val: number) => `${Math.floor(val)}s`,
    [],
  );

  return (
    <div className={classes.embed} ref={onContainerLoad}>
      <video
        className="no-inview-check"
        ref={setMainVideoRef}
        onClick={togglePlayPause}
        autoPlay
        controls={false}
        muted
        playsInline
        loop
        src={videoSRC}
        poster={poster}
      />
      {
        isVideoPaused !== null && (
          <IconButton className={classes.playAnimation}>
            { isVideoPaused ? <PauseIcon /> : <PlayIcon />}
          </IconButton>
        )
      }

      <IconButton
        onClick={toggleMainVideoSound}
        className={classes.muteButton}
      >
        { isVideoMuted ? <VolumeOffIcon /> : <VolumeUpIcon /> }
      </IconButton>

      {
        mainVideoRef && (
          <Slider
            className={classes.slider}
            classes={{
              rail: classes.rail,
              root: classes.slider,
            }}
            color="secondary"
            min={0}
            max={mainVideoRef.duration}
            step={1 / 60}
            value={progress}
            onChange={sliderOnChange}
            valueLabelDisplay="auto"
            valueLabelFormat={sliderLabelFormat}
          />
        )
      }
    </div>
  );
}

export default React.memo(VideoEmbedWithControls);
