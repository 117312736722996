import { syncHistoryWithStore, RouterStore as RouterStoreClass } from '@superwf/mobx-react-router';
import { createBrowserHistory } from 'history';

const RouterStore = new RouterStoreClass();

declare global {
  interface Window {
    RouterStore: RouterStoreClass,
  }
}

window.RouterStore = RouterStore;

export const history = syncHistoryWithStore(createBrowserHistory(), RouterStore);

export default RouterStore;
