import { Button } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import AlertStore from '../../stores/AlertStore';
import LoginStore from '../../stores/LoginStore';
import { ReactComponent as GoogleIcon } from '../../svg/google_icon.svg';
import classes from '../login/LoginPage.module.scss';

export const googleClientID = '590932396162-c1m63jkfd3rio1k5sagdeis9mgbcdap8.apps.googleusercontent.com';

type Props = {
  isVisible?: boolean,
};

// we need to have this globally, to run only once
// even if the component mounted and unmounted multiple times
let didLoad = false;
let needRedirectMode = false;

function LoginWithGoogle(props: Props) {
  const { isVisible = true } = props;

  const attachClickHandler = React.useCallback((ref, auth2: any) => {
    auth2.attachClickHandler(ref, {},
      (googleUser: any) => {

        const tokenID = googleUser.getAuthResponse().id_token;
        LoginStore.login({
          googleToken: tokenID,
        });

      }, (error: any) => {
        console.error(error);
        AlertStore.showError(error);
      });
  }, []);

  const handleGoogleLoginRef = React.useCallback((ref: HTMLButtonElement) => {
    // probably unmounting.
    if (!ref) return;

    if (!window.gapi) {
      console.error('gAPI is not loaded for Google Login Button Init');
      return;
    }

    if (didLoad) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (needRedirectMode) {
        // we need to open with ux_mode = redirect;
        ref.addEventListener('click', () => {
          auth2.signIn({
            ux_mode: 'redirect',
          });
        });
        return;
      }
      // else we attachClickHandler as we would normally do.
      attachClickHandler(ref, auth2);

      // already initiated, no need to continue.
      return;
    }

    didLoad = true;

    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: googleClientID,
        cookiepolicy: 'single_host_origin',
      }).then((auth2: any) => {
        attachClickHandler(ref, auth2);
        return auth2;
      }, (error: any) => {
        // the only possible error here is idpiframe_initialization_failed
        // due to an unsupported environment, and we know this always happens
        // when 3rd party cookies are disabled, so we need to change the ux_mode to redirect.
        console.warn('Google Auth2 Init error', error);
        // if it's an error, we need a redirect;
        needRedirectMode = true;
        const auth2 = window.gapi.auth2.getAuthInstance();

        ref.addEventListener('click', () => {
          auth2.signIn({
            ux_mode: 'redirect',
          });
        });
      });
    });
  }, []);

  if (!isVisible || !window.gapi) return null;

  return (
    <Button
      className={classNames(classes.googleButton)}
      startIcon={<GoogleIcon />}
      variant="contained"
      color="default"
      ref={handleGoogleLoginRef}
    >
      Login with Google
    </Button>
  );
}

export default React.memo(LoginWithGoogle);
