import { action, observable, makeObservable } from 'mobx';
import { formatError } from '../functions/errors';
import { eventList } from '../tracking/events';

type VerticalAlign = 'top' | 'bottom';
type HorizontalAlign = 'left' | 'center' | 'right';

export type SnackbarType = 'info' | 'warn' | 'error';

type Snackbar = {
  message: string,
  vertical?: VerticalAlign,
  horizontal?: HorizontalAlign,
  type?: SnackbarType,
  duration?: number,
};

class AlertStore {
  @observable isVisible: boolean = false;

  @observable vertical: VerticalAlign = 'top';

  @observable horizontal: HorizontalAlign = 'center';

  @observable type: SnackbarType = 'info';

  @observable duration: number = 3000;

  @observable message = '';

  @observable lastAppearance = Date.now();

  @action
  showSnackbar = ({
    message,
    vertical = 'top',
    horizontal = 'center',
    type = 'info',
    duration,
  }: Snackbar) => {
    this.isVisible = true;
    this.vertical = vertical;
    this.horizontal = horizontal;
    this.type = type;
    this.message = message;
    this.duration = duration === undefined ? 10000 : duration;
    this.lastAppearance = Date.now();

    eventList.global.alert({
      type,
      message,
    });
  };

  @action
  hideSnackBar = () => {
    // ignore closings under 200ms
    // usually show on mousedown and Alert onClickAway on mouseUp
    // making it open and close immediately
    if (Date.now() - this.lastAppearance < 200) return;

    this.isVisible = false;
  };

  @action
  showError = (error: any) => {
    if (!error) { return; }
    this.showSnackbar({
      message: formatError(error),
      type: 'error',
    });
  };

  constructor() {
    makeObservable(this);
  }
}

const InitiatedAlertStore = new AlertStore();

declare global {
  interface Window {
    AlertStore: AlertStore,
  }
}

window.AlertStore = InitiatedAlertStore;

export default InitiatedAlertStore;
