import { debounce } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';

import type { ShotListFilterData } from '../components/project/editor/shot_list/ShotListFilterData';
import type { ResultFrameKeys } from '../components/project/results/ResultFrames';
import type { aspectRatioByName } from '../stores/AiveHelpers';
import type { SnackbarType } from '../stores/AlertStore';
import type { ClipOptions, EasingType, JobSettings } from '../stores/JobData';
import type { LoginStoreType } from '../stores/LoginStore';
import type { MediaType, SortType } from '../stores/MediaTypes';
import type { PreviewPhoneFrameUIStore } from '../stores/PreviewPhoneFrameUIStore';
import type { QuotaExceedType, QuotaData } from '../stores/QuotaStore';
import type { LibraryFilterStoreClass } from '../stores/LibraryFilterStore';
import type { LibrarySortTypes } from '../stores/LibraryStore';
import type { GlobalSettingsStore } from '../stores/GlobalSettingsStore';

mixpanel.init(
  'c1c2618015bfdea654e4a8aeb8050c59',
  {
    api_host: 'https://api-eu.mixpanel.com',
  },
  '',
);

type TrackingEvent = {
  action: string,
  oneWord?: string,
  label?: string,
  extraParams?: object,
  value?: number,
  nonInteraction?: true,
};

export type MediaFinishDetails = {
  type: 'local' | 'external',
  mediaType: MediaType,
  duration: number,
  size: number,
  hasAudio: boolean,
  width: number,
  height: number,
  fps: number,
  preprocessTime: number,
  processTime: number,
  uploadTime?: number,
  downloadTime?: number,
};

export const categoryList = {
  global: {
    title: 'Global',
    includeCategory: false,
  },

  navigation: {
    title: 'Navigation',
    includeCategory: true,
  },

  timeline: {
    title: 'Timeline',
    includeCategory: true,
  },

  quota: {
    title: 'Quota',
    includeCategory: true,
  },

  uiSettings: {
    title: 'UI Settings',
    includeCategory: true,
  },

  preview: {
    title: 'Preview',
    includeCategory: true,
  },

  shotsFilter: {
    title: 'Shots Filter',
    includeCategory: true,
  },

  projectMediaPanel: {
    title: 'Project Media Panel',
    includeCategory: true,
  },

  quotaError: {
    title: 'Quota Error',
    includeCategory: true,
  },

  clips: {
    title: 'Timeline Clips',
    includeCategory: true,
  },

  shots: {
    title: 'Shots',
    includeCategory: true,
  },

  projectSettings: {
    title: 'Project Settings',
    includeCategory: true,
  },

  clipSettings: {
    title: 'Clip Settings',
    includeCategory: true,
  },

  captions: {
    title: 'Captions',
    includeCategory: true,
  },

  library: {
    title: 'Library',
    includeCategory: true,
  },

  media: {
    title: 'Media',
    includeCategory: true,
  },

  mediaItem: {
    title: 'Media Item',
    includeCategory: true,
  },

  projectItem: {
    title: 'Project Item',
    includeCategory: true,
  },

  outputs: {
    title: 'Outputs',
    includeCategory: true,
  },

  user: {
    title: 'User',
    includeCategory: true,
  },

  unskippable: {
    title: 'Unskippable',
    includeCategory: true,
  },

  pricing: {
    title: 'Pricing',
    includeCategory: true,
  },

  overlays: {
    title: 'Overlays',
    includeCategory: true,
  },

  keyboardShortcuts: {
    title: 'Keyboard Shortcuts',
    includeCategory: true,
  },

  billing: {
    title: 'Billing',
    includeCategory: true,
  },

  homepage: {
    title: 'Homepage',
    includeCategory: true,
  },

  tinyVid: {
    title: 'TinyVid',
    includeCategory: true,
  },
};

function trackEvent(category: EventCategories, data: TrackingEvent) {
  let eventName = data?.oneWord || data.action;

  const categoryDetails = categoryList[category];

  if (categoryDetails.includeCategory) {
    eventName = `${categoryDetails.title} - ${eventName}`;
  }

  if (window.amplitude) {
    window.amplitude.getInstance().logEvent(eventName, { ...data.extraParams });
  }
  if (window.gtag) {
    window.gtag('event', eventName, { ...data.extraParams });
  }
  if (mixpanel) {
    mixpanel.track(eventName, { ...data.extraParams });
  }
}

export function trackUserProperty(properties: Object) {
  if (window.gtag) {
    window.gtag('set', 'user_properties', properties);
  }

  if (window.amplitude) {
    window.amplitude.getInstance().setUserProperties(properties);
  }
}

export function trackUserData(userData: LoginStoreType, userQuota: QuotaData) {
  const userID = userData.user.userID;

  Sentry.setUser({
    id: userID,
  });

  if (mixpanel) {
    mixpanel.identify(userID);
  }

  if (window.gtag) {
    window.gtag('config', 'GA_MEASUREMENT_ID', {
      user_id: userID,
      purchased_plan: userQuota.plan.id,
    });

    window.gtag('set', 'user_properties', {
      paying: !(userQuota.plan.id === 'free' || userQuota.plan.id === 'free_usd'),
      purchased_plan: userQuota.plan.id,
      isLoggedIn: true,
    });
  }

  if (window.amplitude) {
    window.amplitude.getInstance().setUserId(userID);
    window.amplitude.getInstance().setUserProperties({
      purchased_plan: userQuota.plan.id,
    });
  }

  // eslint-disable-next-line no-multi-assign
  const _hsq = window._hsq = window._hsq || [];

  if (userData.user.email && userData.user.isConfirmed) {
    _hsq.push(['identify', {
      email: userData.user.email,
      id: userID,
      purchased_plan: userQuota.plan.id,
    }]);
  }

  Sentry.setUser({
    id: userID,
  });
}

function enclose<T extends object = never>(
  category: EventCategories,
  data: TrackingEvent,
) {
  return (
    ...extraParams: T extends never ? [undefined?] : [T]
  ) => trackEvent(category, {
    ...data,
    extraParams: extraParams[0],
  });
}

// Default GA4 events:
// add_payment_info
// add_to_cart
// add_to_wishlist
// begin_checkout
// checkout_progress
// generate_lead
// login
// purchase
// refund
// remove_from_cart
// search
// select_content
// set_checkout_option
// share
// sign_up
// view_item
// view_item_list
// view_promotion
// view_search_results

type RenderTrackType = JobSettings & {
  free: boolean,
  isMulti: boolean,
  isRemoveWatermark: boolean,
  customHeight: boolean,
  noRender: boolean,

  hasIntro: boolean,
  hasOutro: boolean,

  numberOfClips: number,
  numberOfOverlays: number,
  numberOfAudioClips: number,
  numberOfSources: number,
};

// if oneWord is missing, it will inherit the action
// if category is missing it will inherit the parent's currentCategory
export const eventList = {
  // events that shouldn't be in a category
  global: {
    clickedUpgrade: enclose<{
      where: 'header' | 'menu',
      reason?: keyof QuotaData['usage'],
    }>('global', {
      action: 'Clicked Upgrade',
      label: 'Header',
    }),

    render: enclose<RenderTrackType>('global', {
      action: 'Render',
    }),

    clickedUndo: enclose('global', {
      action: 'Clicked Undo',
    }),
    clickedRedo: enclose('global', {
      action: 'Clicked Redo',
    }),

    clickedOpenSupportChat: enclose('global', {
      action: 'Clicked Open Support Chat',
    }),

    login: enclose<{ method: 'email' | 'apple' | 'google' | 'facebook' }>('global', {
      action: 'login',
    }),

    signUp: enclose<{ method: 'email' | 'apple' | 'google' | 'facebook' }>('global', {
      action: 'sign_up',
    }),

    paymentSuccessful: enclose('global', {
      action: 'Payment Successful',
    }),

    clickedExpiryTitle: enclose('global', {
      action: 'Clicked Expiry Title',
    }),

    clickedContactUs: enclose('global', {
      action: 'Clicked Contact Us',
    }),

    alert: enclose<{
      type: SnackbarType,
      message: string,
    }>('global', {
      action: 'User Alert',
    }),

    goToCropWizard: enclose('global', {
      action: 'Open Crop Wizard',
    }),

    goToAIWizard: enclose('global', {
      action: 'Open AI Wizard',
    }),

    goToStudio: enclose('global', {
      action: 'Open Studio',
    }),
  },

  navigation: {
    clickedLogo: enclose('navigation', {
      action: 'Clicked Logo',
    }),
    clickedUserMenu: enclose('navigation', {
      action: 'Clicked User Menu',
    }),
    closedUserMenu: enclose('navigation', {
      action: 'Closed User Menu',
    }),
    clickedLogoutMenu: enclose('navigation', {
      action: 'Clicked Logout',
    }),
    clickedNewProject: enclose('navigation', {
      action: 'Clicked New Project',
    }),
    clickedProjects: enclose('navigation', {
      action: 'Clicked My Projects',
    }),
    clickedLibrary: enclose('navigation', {
      action: 'Clicked My Library',
    }),
    clickedProjectSettings: enclose<{ type: 'open' | 'close' }>('navigation', {
      action: 'Clicked Project Settings',
    }),
    clickedCropSettings: enclose<{ type: 'open' | 'close' }>('navigation', {
      action: 'Clicked Crop Settings',
    }),
    clickedMedia: enclose<{ type: 'open' | 'close' }>('navigation', {
      action: 'Clicked Media',
    }),
    clickedOutputs: enclose('navigation', {
      action: 'Clicked Outputs',
    }),
    switchToShotList: enclose('navigation', {
      action: 'Switch to Shotlist',
    }),
    switchToPreview: enclose('navigation', {
      action: 'Switch to Preview',
    }),
  },

  // Quota Category
  quota: {
    clickedQuotaMenu: enclose('quota', {
      action: 'Open Quota Menu',
    }),
    closedQuotaMenu: enclose('quota', {
      action: 'Closed Quota Menu',
    }),
    clickedUpgradeQuotaMenu: enclose('quota', {
      action: 'Clicked Upgrade in Quota Menu',
    }),
    clickedNoThanks: enclose('quota', {
      action: 'Modal - Clicked No Thanks',
    }),
    closeQuotaAlert: enclose('quota', {
      action: 'Modal - Clicked Outside',
    }),
    clickedSeePlanList: enclose('quota', {
      action: 'Modal - Clicked See plan list',
    }),
    clickedUpgradeToPlan: enclose<{ plan: string }>('quota', {
      action: 'Modal - Clicked Upgrade to Plan',
    }),
    clickedContactUs: enclose('quota', {
      action: 'Modal - Clicked Contact Us',
    }),
  },

  quotaError: {
    exceedsQuota: enclose<QuotaExceedType>('quotaError', {
      action: 'Exceeds Quota',
    }),
  },

  timeline: {
    toggleTimeline: enclose<{ type: 'open' | 'close' }>('timeline', {
      action: 'Toggle Timeline',
    }),

    clickedRender: enclose('timeline', {
      action: 'Clicked Render',
    }),
    clickedRenderOptions: enclose('timeline', {
      action: 'Clicked Render Options',
    }),
    changedRenderType: enclose<{ type: 'draft' | 'full' | 'multi' }>('timeline', {
      action: 'Changed Render Type',
    }),
    showMultiRenderScreen: enclose('timeline', {
      action: 'Show Multi Render Screen',
    }),

    draggedPlayHead: debounce(enclose('timeline', {
      action: 'Dragged PlayHead',
    }), 500),
    scrollToPan: debounce(enclose('timeline', {
      action: 'Scroll to Pan',
    }), 500),
    scrollToZoom: debounce(enclose('timeline', {
      action: 'Scroll to Zoom',
    }), 500),
    clicked: enclose('timeline', {
      action: 'Clicked Timeline',
    }),
    switchToSimple: enclose('timeline', {
      action: 'Switch to Simple',
    }),
    switchToAdvanced: enclose('timeline', {
      action: 'Switch to Advanced',
    }),
    clickedSettings: enclose('timeline', {
      action: 'Clicked Settings',
    }),
    clearTimeline: enclose('timeline', {
      action: 'Clicked Clear Timeline',
    }),
    switchToShotList: enclose('timeline', {
      action: 'Switch to Shot List',
    }),
    switchToPreview: enclose('timeline', {
      action: 'Switch to Preview',
    }),
    clickedZoomIn: enclose('timeline', {
      action: 'Clicked Zoom In',
    }),
    clickedZoomOut: enclose('timeline', {
      action: 'Clicked Zoom Out',
    }),
    draggedZoomSlider: debounce(enclose('timeline', {
      action: 'Dragged Zoom Slider',
    }), 500),
    draggedScrollbar: debounce(enclose('timeline', {
      action: 'Dragged Scrollbar',
    }), 500),
    // source refers to actual segment in the timeline
    // preview refers to dragging a clip in the timeline with TimelineTrimDraggable
    setTrimBegin: enclose<{ where: 'source' | 'preview' }>('timeline', {
      action: 'Set Trim Begin',
    }),
    setTrimEnd: enclose<{ where: 'source' | 'preview' }>('timeline', {
      action: 'Set Trim End',
    }),
    split: enclose<{ where: 'source' | 'preview' }>('timeline', {
      action: 'Split',
    }),
    trimDragBeginHandle: debounce(enclose<{ where: 'source' | 'preview' }>('timeline', {
      action: 'Trim by dragging Begin',
      // in mediaTimeline or projectTimeline
    }), 500),
    trimDragEndHandle: debounce(enclose<{ where: 'source' | 'preview' }>('timeline', {
      action: 'Trim by dragging End',
    }), 500),
    draggedSegment: debounce(enclose('timeline', {
      action: 'Dragged Clip Segment',
    }), 500),
  },

  uiSettings: {
    toggleSound: enclose<{ type: 'on' | 'off' }>('preview', {
      action: 'Clicked to Toggle Sound',
    }),

    switchProjectListView: enclose<{
      type:(typeof GlobalSettingsStore)['projectViewMode'],
    }>('uiSettings', { action: 'Switched Project List View' }),

    switchOutputsListView: enclose<{
      type:(typeof GlobalSettingsStore)['outputsViewMode'],
    }>('uiSettings', { action: 'Switched Outputs List View' }),
  },

  preview: {
    goToBeginning: enclose<{ withShift: boolean }>('preview', {
      action: 'Go to Beginning',
    }),
    goToEnd: enclose<{ withShift: boolean }>('preview', {
      action: 'Go to End',
    }),
    toggleCropVisibility: enclose<{ type: 'visible' | 'hidden' }>('preview', {
      action: 'Crop Visibility',
    }),
    clickedVideoQuality: enclose('preview', {
      action: 'Clicked Preview Quality',
    }),
    changedPreviewQuality: enclose<{ type: 'sd' | 'hd' }>('preview', {
      action: 'Changed Preview Quality',
    }),
    selectedSocialPhoneFrame: enclose<{
      type: PreviewPhoneFrameUIStore['activeOverlayKey'],
    }>('preview', {
      action: 'Selected Social Phone Frame',
    }),
    clickedPreviewFullScreen: enclose('preview', {
      action: 'Clicked Preview Full-Screen',
    }),
    goToNextClip: enclose('preview', {
      action: 'Clicked Go to Next Clip (Arrow)',
    }),
    goToPreviousClip: enclose('preview', {
      action: 'Clicked Go to Previous Clip (Arrow)',
    }),
    play: enclose('preview', {
      action: 'Play',
    }),
    playClip: enclose<{ where: 'source' | 'preview' }>('preview', {
      action: 'Play Clip',
    }),
    pause: enclose('preview', {
      action: 'Pause',
    }),
    pauseClip: enclose<{ where: 'source' | 'preview' }>('preview', {
      action: 'Pause Clip',
    }),
    dragCropOverlay: debounce(enclose<{
      type: 'resize' | 'drag',
      zoom?: number,
    }>('preview', {
      action: 'Drag Crop Overlay',
    }), 500),
    dragCustomTrack: debounce(enclose<{
      type: 'create' | 'move',
    }>('preview', {
      action: 'Drag Custom Track',
    }), 500),

    openCropOptions: enclose('preview', {
      action: 'Open Crop Overlay Options',
    }),
    copyCropPosition: enclose('preview', {
      action: 'Crop - Copy Position',
    }),
    pasteCropPosition: enclose('preview', {
      action: 'Crop - Paste Position',
    }),
    matchCropToLastClip: enclose('preview', {
      action: 'Crop - Match to last Clip Crop',
    }),
    matchCropToAutoCrop: enclose('preview', {
      action: 'Crop - Match to AutoCrop',
    }),
  },

  shotsFilter: {
    open: enclose('shotsFilter', {
      action: 'Open',
    }),
    close: enclose('shotsFilter', {
      action: 'Close',
    }),
    filter: enclose<ShotListFilterData>('shotsFilter', {
      action: 'Change Filter',
    }),
    toggleTransitions: enclose<{
      visibility: 'visible' | 'hidden',
    }>('shotsFilter', {
      action: 'Toggle Transitions',
    }),
    ignoreTransitions: enclose<{
      visibility: 'visible' | 'hidden',
    }>('shotsFilter', {
      action: 'Ignore Transitions',
    }),
  },

  projectMediaPanel: {
    selectFromLibrary: enclose('projectMediaPanel', {
      action: 'Select from library',
    }),
    addMediaLinks: enclose('projectMediaPanel', {
      action: 'Add Media links',
    }),
    pasteMediaLink: enclose<{ numberOfLinks: number }>('projectMediaPanel', {
      action: 'Pasted Media Link',
    }),
    addVideoLinks: enclose<{ numberOfLinks: number }>('projectMediaPanel', {
      action: 'Add Media links to Project',
    }),
    uploadLocalFile: enclose<{ numberOfFiles: number }>('projectMediaPanel', {
      action: 'Upload local file',
    }),
    agreeAndUpload: enclose('projectMediaPanel', {
      action: 'Clicked Agree and Upload',
    }),
  },

  shots: {
    selectedShot: enclose('shots', {
      action: 'Selected Shot',
    }),
    deselectShot: enclose('shots', {
      action: 'Deselect Shot',
    }),
    selectedMultipleShots: enclose('shots', {
      action: 'Selected Multiple Shots',
    }),

    addedShotToTimeline: enclose<{
      addMethod: 'shotModalButton' | 'clickOnShot' | 'addMultipleShotsButton' | 'addAllShotsCardButton',
      numberOfShots?: number,
      prompt?: 'replace' | 'atBegin' | 'atEnd',
    }>('shots', {
      action: 'Added Shots to Timeline',
    }),

    // only for more than 5s
    shotHovered: enclose<{
      hoverDuration: number,
    }>('shots', {
      action: 'Shot hovered',
    }),

    removeAllShots: enclose('shots', {
      action: 'Remove all instances of this Shot',
    }),

    openShotModal: enclose('shots', {
      action: 'Open Shot Modal',
    }),
    closeShotModal: enclose('shots', {
      action: 'Close Shot Modal',
    }),
    mergeShots: enclose('shots', {
      action: 'Merge Shots',
    }),

    confirmedShotMerge: enclose<{
      reason: 'fix' | 'preference',
    }>('shots', {
      action: 'Confirm Shot Merge',
    }),

    splitShot: enclose('shots', {
      action: 'Split Shot',
    }),

    confirmedShotSplit: enclose<{
      reason: 'fix' | 'preference',
    }>('shots', {
      action: 'Confirm Shot Split',
    }),

    clearSelection: enclose('shots', {
      action: 'Clear Selection',
    }),
    resetChanges: enclose('shots', {
      action: 'Reset Shot Changes',
    }),
    clickedPlay: enclose('shots', {
      action: 'Clicked Play in Shot Modal',
    }),
    clickedPause: enclose('shots', {
      action: 'Clicked Pause in Shot Modal',
    }),
    dragShot: enclose('shots', {
      action: 'Dragged Shot',
    }),
    clickedShotIndex: enclose('shots', {
      action: 'Clicked Shot Index',
    }),
    play: enclose('shots', {
      action: 'Play',
    }),
    pause: enclose('shots', {
      action: 'Pause',
    }),
  },

  clips: {
    // Clips in Timeline
    discardClip: enclose('clips', {
      action: 'Discard Clip from Timeline',
    }),
    click: enclose('clips', {
      action: 'Click Clip',
    }),
    editClip: enclose('clips', {
      action: 'Edit Clip',
    }),
    clipHovered: enclose('clips', {
      action: 'Clip Hovered',
    }),
    closeClipEditModal: enclose('clips', {
      action: 'Closed Clip Modal',
    }),
    duplicate: enclose('clips', {
      action: 'Duplicate',
    }),
    dragTrimBegin: enclose('clips', {
      action: 'Drag Trim Begin',
    }),
    dragTrimEnd: enclose('clips', {
      action: 'Drag Trim End',
    }),
  },

  projectSettings: {
    editProjectName: enclose('projectSettings', {
      action: 'Clicked to edit Project Name',
    }),

    openARSelector: enclose('projectSettings', {
      action: 'Open AR Selector',
    }),

    changeAspectRatio: enclose<{
      aspectRatio: keyof typeof aspectRatioByName | 'original' | 'custom',
      customAR?: number,
      customARWidth?: number,
      customARHeight?: number,
      fromFeaturedAR?: boolean,
    }>('projectSettings', {
      action: 'Change Aspect Ratio',
    }),

    toggleAutoCrop: enclose<{
      type: 'on' | 'off',
    }>('projectSettings', {
      action: 'Toggle AutoCrop',
    }),

    setCropFocalPoint: enclose('projectSettings', {
      action: 'Crop - Focal Point',
    }),

    toggleBlackBars: enclose<{
      type: 'on' | 'off',
    }>('projectSettings', {
      action: 'Toggle Remove Black Bars',
    }),

    toggleAudio: enclose<{
      type: 'on' | 'off',
    }>('projectSettings', {
      action: 'Toggle Audio',
    }),

    processBlackBars: enclose('projectSettings', {
      action: 'Process Black Bars',
    }),

    editBlackBars: enclose('projectSettings', {
      action: 'Edit Black Bars',
    }),

    editCaptions: enclose('projectSettings', {
      action: 'Edit Captions',
    }),

    changeDuration: enclose<{ duration: number }>('projectSettings', {
      action: 'Change Duration',
    }),

    changeSpeed: enclose<{ speed: number }>('projectSettings', {
      action: 'Change Speed',
    }),

    changeAudioCrossFade: enclose<{ duration: number }>('projectSettings', {
      action: 'Change Audio Cross-fade duration',
    }),

    changeCurrentlySelectedMedia: enclose<{
      from: 'projectMediaPanel' | 'shotListSource' | 'blackBarsMedia' | 'captionsMedia'
    }>('projectSettings', {
      action: 'Changed Currently Selected Media',
    }),
  },

  clipSettings: {
    resetClipSettings: enclose('clipSettings', {
      action: 'Reset Clip Settings',
    }),
    duplicateClip: enclose('clipSettings', {
      action: 'Duplicate Clip',
    }),
    deleteClip: enclose('clipSettings', {
      action: 'Delete Clip',
    }),

    toggleAutoCrop: enclose<{
      type: 'on' | 'off',
    }>('clipSettings', {
      action: 'Toggle AutoCrop',
    }),

    toggleBlackBars: enclose<{
      type: 'on' | 'off',
    }>('clipSettings', {
      action: 'Toggle Remove Black Bars',
    }),

    toggleAudio: enclose<{
      type: 'on' | 'off',
    }>('clipSettings', {
      action: 'Toggle Audio',
    }),

    changeVolume: enclose<{
      volume: number,
    }>('clipSettings', {
      action: 'Change Clip Volume',
    }),

    toggleFreezeFrame: enclose<{
      type: 'on' | 'off',
    }>('clipSettings', {
      action: 'Toggle Freeze Frame',
    }),

    toggleReverse: enclose<{
      type: 'on' | 'off',
    }>('clipSettings', {
      action: 'Toggle Reverse',
    }),

    toggleBackgroundBlur: enclose<{
      type: 'on' | 'off',
    }>('clipSettings', {
      action: 'Toggle Background Blur',
    }),

    toggleFitVideo: enclose<{
      type: 'on' | 'off',
    }>('clipSettings', {
      action: 'Toggle Fit Video',
    }),

    changeBackgroundBlur: enclose<{
      backgroundBlur: number,
    }>('clipSettings', {
      action: 'Change Background Blur',
    }),

    changeBackgroundColor: enclose<{
      backgroundColor: string,
    }>('clipSettings', {
      action: 'Change Background Color',
    }),

    changeClipZoom: debounce(enclose<{
      zoom: number,
    }>('clipSettings', {
      action: 'Change Clip Zoom',
    }), 500),

    trackCustomObject: enclose('clipSettings', {
      action: 'Track custom object',
    }),

    applyCustomTrack: enclose('clipSettings', {
      action: 'Apply & Process Custom Track',
    }),

    deleteCustomTrack: enclose('clipSettings', {
      action: 'Delete Custom Track',
    }),

    exitCustomTrack: enclose('clipSettings', {
      action: 'Exit Custom Track',
    }),

    addKeyframe: enclose('clipSettings', {
      action: 'Add Keyframe',
    }),

    removeCurrentKeyframe: enclose('clipSettings', {
      action: 'Remove Current Keyframe',
    }),

    removeAllKeyframes: enclose('clipSettings', {
      action: 'Remove All Keyframes',
    }),

    changeKeyFrameEasing: enclose<{
      easing: EasingType,
    }>('clipSettings', {
      action: 'Change Keyframe Easing',
    }),

    changeClipSpeed: enclose<{
      speed: number,
    }>('clipSettings', {
      action: 'Change Speed',
    }),

    setSuperSlowMotion: enclose<{
      speed: number,
    }>('clipSettings', {
      action: 'Set Super Slow Motion',
    }),

    toggleCrossFade: enclose<{
      type: 'on' | 'off',
    }>('clipSettings', {
      action: 'Toggle Cross Fade',
    }),

    changeCrossFadeType: enclose<{
      crossFade: Required<ClipOptions>['crossFadeWithNextClip']['transition'],
    }>('clipSettings', {
      action: 'Set Cross Fade type',
    }),

    changeCrossFadeDuration: enclose<{
      duration: number,
    }>('clipSettings', {
      action: 'Change Cross Fade Duration',
    }),

    toggleFadeIn: enclose<{
      type: 'on' | 'off',
    }>('clipSettings', {
      action: 'Toggle Fade In',
    }),
    toggleFadeOut: enclose<{
      type: 'on' | 'off',
    }>('clipSettings', {
      action: 'Toggle Fade Out',
    }),

    changeFadeIn: enclose<{
      color: string,
      duration: number,
    }>('clipSettings', {
      action: 'Change Fade In',
    }),

    changeFadeOut: enclose<{
      color: string,
      duration: number,
    }>('clipSettings', {
      action: 'Change Fade In',
    }),

    changeHue: enclose<{
      hue: number,
    }>('clipSettings', {
      action: 'Changed HUE',
    }),
    changeBrightness: enclose<{
      brightness: number,
    }>('clipSettings', {
      action: 'Change Brightness',
    }),
    changeContrast: enclose<{
      contrast: number,
    }>('clipSettings', {
      action: 'Change Contrast',
    }),
    changeSaturation: enclose<{
      saturation: number,
    }>('clipSettings', {
      action: 'Change Saturation',
    }),

    toggleTint: enclose<{
      type: 'on' | 'off',
    }>('clipSettings', {
      action: 'Enable Tint',
    }),

    chooseLowLightColor: enclose<{
      color: string,
    }>('clipSettings', {
      action: 'Tint - Change Low Light Color',
    }),
    chooseHighLightColor: enclose<{
      color: string,
    }>('clipSettings', {
      action: 'Tint - Change High Light Color',
    }),
    swapColors: enclose('clipSettings', {
      action: 'Tint - Swap colors',
    }),
    chooseTintPresent: enclose<{
      preset: string,
    }>('clipSettings', {
      action: 'Tint - Choose Tint Preset',
    }),
  },

  captions: {
    clickedGenerate: enclose('captions', {
      action: 'Nav - Clicked Generate',
    }),
    clickedOptions: enclose('captions', {
      action: 'Nav - Clicked Options',
    }),
    clickedTranscript: enclose('captions', {
      action: 'Nav - Clicked Transcript',
    }),
    toggleCaptions: enclose<{
      type: 'on' | 'off',
    }>('captions', {
      action: 'Toggle Captions',
    }),
    changeFontFamily: enclose<{
      font: string
    }>('captions', {
      action: 'Change Font Family',
    }),
    toggleBold: enclose<{
      type: 'on' | 'off',
    }>('captions', {
      action: 'Toggle Bold',
    }),
    toggleItalic: enclose<{
      type: 'on' | 'off',
    }>('captions', {
      action: 'Toggle Bold',
    }),
    changeFontColor: enclose<{
      color: string,
    }>('captions', {
      action: 'Change Font Color',
    }),
    changeFontBackground: enclose<{
      color: string,
    }>('captions', {
      action: 'Change Font Background',
    }),
    changeFontSize: enclose<{
      size: number,
    }>('captions', {
      action: 'Change Font Size',
    }),
    changeNumberOfLines: enclose<{
      lines: number,
    }>('captions', {
      action: 'Change Number of Lines',
    }),
    changeAnimationDuration: enclose<{
      duration: number,
    }>('captions', {
      action: 'Change Animation Duration',
    }),
    setBorderRadius: enclose<{
      radius: number,
    }>('captions', {
      action: 'Set Border Radius',
    }),
    setHorizontalPadding: enclose<{
      padding: number,
    }>('captions', {
      action: 'Set Horizontal Padding',
    }),

    enableRTL: enclose('captions', {
      action: 'Enable RTL',
    }),
    disableRTL: enclose('captions', {
      action: 'Disable RTL',
    }),

    processMedia: enclose<{
      language: string,
    }>('captions', {
      action: 'Process Media',
    }),
    alertGoToGenerate: enclose('captions', {
      action: 'Clicked Alert - Go to Generate',
    }),
  },

  library: {
    clickedAddMedia: enclose('library', {
      action: 'Clicked Add Media',
    }),

    newFolder: enclose<{
      where: 'library' | 'folderSelectModal'
    }>('library', {
      action: 'Create New Folder',
    }),

    goUpByOne: enclose<{
      where: 'library' | 'folderSelectModal'
    }>('library', {
      action: 'Clicked Go Up by One',
    }),

    clickedGoHome: enclose<{
      where: 'library' | 'folderSelectModal'
    }>('library', {
      action: 'Clicked Go Home',
    }),

    clickedBreadCrumb: enclose<{
      where: 'library' | 'folderSelectModal'
    }>('library', {
      action: 'Clicked Bread Crumb',
    }),

    filter: enclose<{
      fileType?: LibraryFilterStoreClass['fileType'],
      aspectRatio?: LibraryFilterStoreClass['aspectRatio'],
      fileSize?: LibraryFilterStoreClass['fileSize'],
      duration?: LibraryFilterStoreClass['duration'],
      pixelCount?: LibraryFilterStoreClass['pixelCount'],
      addedBetween?: boolean,
    }>('library', {
      action: 'Filter',
    }),

    search: debounce(enclose<{
      searchString: string,
    }>('library', {
      action: 'Search',
    }), 1000),

    sortBy: enclose<{
      sortType?: LibrarySortTypes,
      direction?: 'asc' | 'desc',
    }>('library', {
      action: 'Sort',
    }),

    switchView: enclose<{
      type: 'list' | 'grid',
    }>('library', {
      action: 'Switched View',
    }),

    clickedShowFolderDetails: enclose<{
      fromContext?: boolean,
    }>('library', {
      action: 'Clicked Folder Details',
    }),

    clickedItem: enclose<{
      item: 'media' | 'folder',
    }>('library', {
      action: 'Clicked Item',
    }),

    navigateToFolder: enclose<{
      where: 'library' | 'folderSelectModal'
    }>('library', {
      action: 'Navigate to Folder',
    }),

    clickedItemOptions: enclose<{
      withRightClick?: boolean,
    }>('library', {
      action: 'Clicked Item Options',
    }),

    clickedMoveItem: enclose<{
      item: 'media' | 'folder',
    }>('library', {
      action: 'Clicked Move Item',
    }),

    moveItem: enclose<{
      item: 'media' | 'folder',
      numberOfItems?: number,
    }>('library', {
      action: 'Move Item',
    }),

    dragItems: debounce(enclose<{
      item: 'media' | 'folder',
      multipleItems?: boolean,
    }>('library', {
      action: 'Drag Items',
    }), 500),

    clickedEditFolder: enclose('library', {
      action: 'Clicked Edit Folder',
    }),

    clickedEditTitle: enclose('library', {
      action: 'Clicked Edit Media Title',
    }),

    changeFolderColor: enclose<{
      color: string,
    }>('library', {
      action: 'Change Folder Color',
    }),

    moveSelectedItemsIntoFolder: enclose<{
      numberOfItems?: number,
    }>('library', {
      action: 'Move Selected Items Into Folder',
    }),

    toggleLibraryCartExtend: enclose<{
      type: 'on' | 'off',
    }>('library', {
      action: 'Toggle Library Cart Extend',
    }),

    createProjectWithMedia: enclose<{
      numberOfItems: number,
    }>('library', {
      action: 'Create Project with Media',
    }),

    clickedMoveSelectedItems: enclose('library', {
      action: 'Clicked Move Selected Items',
    }),

    clickedClearSelection: enclose('library', {
      action: 'Clicked Clear selection',
    }),

    deleteMedia: enclose<{
      numberOfItems: number,
    }>('library', {
      action: 'Delete Media',
    }),
  },

  media: {
    addMedia: enclose<{
      type: 'local' | 'external',
    }>('media', {
      action: 'Add',
    }),

    startedUploading: enclose<{
      size: number, // in MB
      duration: number | undefined // Video Duration
    }>('media', {
      action: 'Started Uploading',
    }),

    finishedUploading: enclose<{
      size: number, // in MB
      duration: number | undefined // Video Duration
    }>('media', {
      action: 'Finished Uploading',
    }),

    uploadErrored: enclose<{ error: string }>('media', {
      action: 'Upload errored',
    }),

    startedPreprocessing: enclose('media', {
      action: 'Started Preprocessing',
    }),

    finishedPreprocessing: enclose('media', {
      action: 'Finished Preprocessing',
    }),

    startedProcessing: enclose('media', {
      action: 'Started Processing',
    }),

    finishedProcessing: enclose<MediaFinishDetails>('media', {
      action: 'Finished Processing',
    }),
  },

  mediaItem: {
    downloadOriginal: enclose('mediaItem', {
      action: 'Download Original',
    }),
    viewRelatedProjects: enclose('mediaItem', {
      action: 'View Related Projects',
    }),
    preview: enclose('mediaItem', {
      action: 'Preview',
    }),
    openOriginalVideo: enclose('mediaItem', {
      action: 'Open Original Media',
    }),
    copyOriginalVideoLink: enclose('mediaItem', {
      action: 'Copy original video link',
    }),
    delete: enclose('mediaItem', {
      action: 'Delete',
    }),
    remove: enclose('mediaItem', {
      action: 'Remove from Project',
    }),
    scrub: enclose<{
      duration: number
    }>('mediaItem', {
      action: 'Scrub',
    }),
    openedContextMenu: enclose('mediaItem', {
      action: 'Opened Context Menu',
    }),
  },

  projectItem: {
    editName: enclose('projectItem', {
      action: 'Edit Name',
    }),
    delete: enclose('projectItem', {
      action: 'Delete',
    }),
  },

  outputs: {
    clickedBackToProject: enclose('outputs', {
      action: 'Clicked Back to Project',
    }),
    sort: enclose<{
      sortType: SortType,
      direction: 'asc' | 'desc',
    }>('outputs', {
      action: 'Sort',
    }),
    download: enclose<{
      isFree: boolean,
    }>('outputs', {
      action: 'Download',
    }),

    removeWatermark: enclose('outputs', {
      action: 'Remove Watermark',
    }),

    downloadGIF: enclose<{
      gifType: string,
      size?: number,
      fps?: number,
      dithering?: string,
      isFree: boolean,
    }>('outputs', {
      action: 'GIF Download',
    }),

    clickEditOutput: enclose('outputs', {
      action: 'Clicked Edit Output',
    }),

    confirmOverwrite: enclose('outputs', {
      action: 'Confirm Project Overwrite',
    }),

    clickedShare: enclose('outputs', {
      action: 'Clicked Share',
    }),

    clickedCopyToClipboard: enclose('outputs', {
      action: 'Clicked Copy to Clipboard',
    }),
    clickedMoreOption: enclose('outputs', {
      action: 'Clicked More Options',
    }),

    clickedToEditTitle: enclose('outputs', {
      action: 'Clicked to Edit Title',
    }),

    changedTitle: debounce(enclose('outputs', {
      action: 'Changed Title',
    }), 500),

    clickedMoreDownload: enclose('outputs', {
      action: 'More - Clicked Download',
    }),
    clickedMoreEdit: enclose('outputs', {
      action: 'More - Edit from here',
    }),
    importToLibrary: enclose('outputs', {
      action: 'More - Import to Library',
    }),
    moreDelete: enclose('outputs', {
      action: 'More - Delete',
    }),
    showFrame: enclose<{
      frame: Nullable<ResultFrameKeys>,
    }>('outputs', {
      action: 'More - Show Frame',
    }),
  },

  user: {
    showConfirmDialog: enclose('user', {
      action: 'Show Confirmation Dialog',
    }),
    emailConfirmation: enclose('user', {
      action: 'Confirmed Email',
    }),
    sendRecoveryEmail: enclose('user', {
      action: 'Send Recovery Link',
    }),
    agreedWithTerms: enclose('user', {
      action: 'Agreed with Terms',
    }),
    openedTerms: enclose('user', {
      action: 'Opened Terms of Service',
    }),
    openedPrivacyPolicy: enclose('user', {
      action: 'Opened Privacy Policy',
    }),
  },

  unskippable: {
    showPage: enclose('unskippable', {
      action: 'Show Unskippable',
    }),
    video50: enclose('unskippable', {
      action: 'Video Progress - 50%',
    }),
    video100: enclose('unskippable', {
      action: 'Video Progress - Watched',
    }),
    skip: enclose('unskippable', {
      action: 'Skipped Video',
    }),
  },

  pricing: {
    switchedToMonthly: enclose('pricing', {
      action: 'Switched to Monthly',
    }),
    switchedToAnnual: enclose('pricing', {
      action: 'Switched to Annual',
    }),
    clickedChoosePlan: enclose<{
      plan: string,
    }>('pricing', {
      action: 'Clicked Choose Plan',
    }),
    showPricingTable: enclose('pricing', {
      action: 'Show Pricing Table',
    }),
  },

  overlays: {
    add: enclose('overlays', {
      action: 'Add Overlay',
    }),

    deleteOverlay: enclose('overlays', {
      action: 'Delete Overlay',
    }),

    toggleAspectRatio: enclose('overlays', {
      action: 'Toggle Keep Aspect Ratio',
    }),

    changeOpacity: enclose<{
      opacity: number,
    }>('overlays', {
      action: 'Change Opacity',
    }),

    changeOrder: enclose<{
      order: number,
    }>('overlays', {
      action: 'Change Order',
    }),

    changeDuration: enclose('overlays', {
      action: 'Change Duration',
    }),

    matchToCurrentClip: enclose('overlays', {
      action: 'Math to Current Clip',
    }),

    fitToVideo: enclose('overlays', {
      action: 'Fit to Video',
    }),
  },

  keyboardShortcuts: {
    split: enclose('keyboardShortcuts', {
      action: 'Split Clip',
    }),
    setPlayHeadToClipBegin: enclose('keyboardShortcuts', {
      action: 'Set Playhead to Clip Begin',
    }),
    setPlayHeadToClipEnd: enclose('keyboardShortcuts', {
      action: 'Set Playhead to Clip Begin',
    }),
    setTrimBegin: enclose('keyboardShortcuts', {
      action: 'Set Trim Begin',
    }),
    setTrimEnd: enclose('keyboardShortcuts', {
      action: 'Set Trim End',
    }),
    zoom: enclose<{ type: 'in' | 'out' }>('keyboardShortcuts', {
      action: 'Zoom',
    }),
    skip: enclose<{ type: 'before' | 'after' }>('keyboardShortcuts', {
      action: 'Clip Skip (Arrow Up / Down)',
    }),
    showKBShortcuts: enclose('keyboardShortcuts', {
      action: 'Show Modal with KB Shortcuts',
    }),
    changeVolume: enclose<{ type: 'up' | 'down' }>('keyboardShortcuts', {
      action: 'Change Clip Volume',
    }),
    deleteClip: enclose('keyboardShortcuts', {
      action: 'Delete Clip',
    }),
  },

  billing: {
    clickedUpgrade: enclose('billing', {
      action: 'Clicked Upgrade',
    }),
    clickedChangePlan: enclose('billing', {
      action: 'Clicked Change Plan',
    }),
  },

  homepage: {
    clickedLogin: enclose('navigation', {
      action: 'Clicked Login',
    }),
    clickedLogout: enclose('navigation', {
      action: 'Clicked Logout',
    }),
    clickedHPTryNow: enclose('navigation', {
      action: 'With Email Try now',
    }),
    moreDetailsSubmit: enclose('navigation', {
      action: 'Submitted More Details',
    }),
  },

  tinyVid: {
    download: enclose<{ type: 'mac' | 'win' | 'linux' }>('tinyVid', {
      action: 'Download',
    }),
    shared: enclose('tinyVid', {
      action: 'Shared on Mobile',
    }),
  },
};

export type EventCategories = keyof typeof categoryList;
