// Check if the video are in view.
// If they're not, pause playing, else resume.
const isElementInView = (el: HTMLVideoElement) => {
  const scroll = window.scrollY || window.pageYOffset;
  const boundsTop = el.getBoundingClientRect().top + scroll;

  const viewport = {
    top: scroll,
    bottom: scroll + window.innerHeight,
  };

  const bounds = {
    top: boundsTop,
    bottom: boundsTop + el.clientHeight,
  };

  return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom)
    || (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
};

function checkIfVideosAreInViewport() {
  const videos = document.querySelectorAll('video');

  videos.forEach(vid => {
    if (vid.classList.contains('no-inview-check')) {
      return;
    }

    vid.muted = true;
    const inView = isElementInView(vid);
    if (inView && vid.paused) {
      vid.play().catch(e => { console.log('Play Error:', e); });
    } else if (!inView && !vid.paused) {
      vid.pause();
    }
  });
}

function throttle(func: any, wait = 100) {
  let timer: null | NodeJS.Timeout = null;
  return function (...args: any) {
    if (timer === null) {
      timer = setTimeout(() => {
        // @ts-ignore
        func.apply(this, args);
        timer = null;
      }, wait);
    }
  };
}

window.videoCheckHandler = throttle(checkIfVideosAreInViewport, 250);

declare global {
  interface Window {
    videoCheckHandler: () => void;
  }
}

export {};
