/* eslint-disable react/jsx-no-target-blank */
import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import classes from './ExitWarning.module.scss';

function ExitWarning() {
  const [hidden, setHidden] = useState(localStorage.getItem('bannerHidden') === 'true');

  const hideBanner = React.useCallback(() => {
    localStorage.setItem('bannerHidden', 'true');
    setHidden(true);
  }, [setHidden]);

  if (hidden) {
    return null;
  }

  return (
    <Alert
      className={classes.exitWarning}
      severity="warning"
    >
      Kamua has been acquired by Jellysmack.
      <a className={classes.link} target="_blank" href="https://help.kamua.com/why-is-my-kamua-account-no-longer-available">
        Read more.
      </a>

      <IconButton
        className={classes.closeButton}
        size="small"
        onClick={hideBanner}
      >
        <Close />
      </IconButton>
    </Alert>
  );
}

export default React.memo(ExitWarning);
