/**
 Copyright Findie 2020
 */
import * as Sentry from '@sentry/browser';
import assert from 'assert';
import { envIsProd } from './isProd';

export function assertExists<T>(value: Maybe<T>, message?: string | Error): asserts value {

  const assertCheck = value !== undefined && value !== null;

  if (assertCheck) {
    return;
  }

  let msg = message;
  if (!msg) {
    const stack = (new Error().stack || '').split('\n');
    const line = (stack && stack[2] ? stack[2] : 'at <unknown>').trim();

    msg = `Found invalid null or undefined value when checking ${line}`;
  }

  if (envIsProd) {
    const ex = msg instanceof Error ? new Error(msg.message) : new Error(msg);
    Sentry.captureException(ex);
  } else {
    assert(assertCheck, msg);
  }
}

type AssertTypeString<T> =
  T extends null ? 'null' :
    T extends string ? 'string' :
      T extends number ? 'number' :
        T extends boolean ? 'boolean' :
          T extends object ? 'object' :
            (value: T) => boolean;

export function assertType<T>(
  value: any,
  type: AssertTypeString<T>,
  message?: string | Error,
): asserts value is T {
  if (typeof type === 'function') {
    assert((type as (value: T) => boolean)(value), message);
  } else {
    assert(typeof value === type, message);
  }
}

export function nonNullable<T>(value: Nullable<T>): value is NonNullable<T> {
  return value !== undefined && value !== null;
}

export function isNullable<T>(value: Maybe<T>): value is null | undefined {
  return value === undefined || value === null;
}

export function enforceObjectRecordIntegrity<T>() {
  // used just to assert types
  return <Keys extends string>(object: Record<Keys, T>) => object;
}
