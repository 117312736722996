/* eslint-disable */

// Load modules

import Assert from 'assert';

const assert = function (condition: boolean, ...args: (string | Error)[]) {

  if (condition) {
    return;
  }

  if (args.length === 1 && args[0] instanceof Error) {
    throw args[0];
  }

  const msgs = args
    .filter((arg) => arg !== '')
    .map((arg) => {

      return typeof arg === 'string' ? arg : arg instanceof Error ? arg.message : exports.stringify(arg);
    });

  throw new Assert.AssertionError({
    message: msgs.join(' ') || 'Unknown error',
    actual: false,
    expected: true,
    operator: '==',
    // out of standard - See commit e5d735746a2381d2be1dfbde6cf18566850bcc18
    // @ts-ignore
    stackStartFunction: assert,
  });
};


const ignore = function () {
};

export {
  assert,
  ignore
};
