/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SecurityIcon from '@material-ui/icons/Security';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DescriptionIcon from '@material-ui/icons/Description';
import HttpsIcon from '@material-ui/icons/Https';
import HelpIcon from '@material-ui/icons/Help';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import Logo from './Logo';

import ConfirmationStore from './stores/ConfirmationStore';
import LoginStore from './aive/src/stores/LoginStore';

import classes from './Header.module.scss';
import LoginModal from './LoginModal';
import RouterStoreHomePage from './RouterStoreHomePage';
import ExitWarning from './aive/src/components/atoms/ExitWarning';

const isMobile = window.innerWidth <= 767;
const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

function Header() {
  const [isMenuOpen, setMenuOpen] = React.useState(false);

  const toggleMenu = React.useCallback(() => {
    setMenuOpen(!isMenuOpen);
  }, [isMenuOpen, setMenuOpen]);

  const closeMenu = React.useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  const openMenu = React.useCallback(() => {
    setMenuOpen(true);
  }, [setMenuOpen]);

  // eslint-disable-next-line import/no-named-as-default-member
  const isTinyVidActive = RouterStoreHomePage.location.pathname.includes('tinyvid');
  console.log('isTinyVidActive?', isTinyVidActive);
  return (
    <>
      <header className={classes.header}>
        <Link to="/" id="kamua-logo" className={classes.logoContainer}>
          <Logo />
        </Link>

        {
          !isMobile && (
            <div className={classes.headerOptions}>

              <Link
                to="/tinyvid"
                className={classNames(isTinyVidActive && classes.activeLink)}
              >
                TinyVid
              </Link>

              {
                isTinyVidActive
                  ? (
                    <>
                      {/* eslint-disable-next-line react/jsx-no-target-blank */}
                      <a href="https://kamua.com/blog/" target="_blank">Blog</a>
                      <Link to="/">
                        Back to Kamua
                      </Link>
                    </>
                  )
                  : (
                    <>
                      {/* eslint-disable-next-line react/jsx-no-target-blank */}
                      <a href="https://kamua.com/blog/" target="_blank">Blog</a>
                      <a target="_blank" href="https://status.kamua.com">Status</a>
                    </>
                  )
              }

              {
                !isTinyVidActive
                && LoginStore.isLoggedIn
                && LoginStore.isConfirmed
                && (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => { window.location.href = '/beta/app/'; }}
                    startIcon={<ExitToAppIcon />}
                  >
                    Go to App
                  </Button>
                )
              }

              { !isTinyVidActive && !LoginStore.isLoggedIn && (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => { window.location.href = '/beta/app/'; }}
                  startIcon={<ExitToAppIcon />}
                >
                  Login
                </Button>
              )}
            </div>
          )
        }

        {
          !isTinyVidActive && isMobile && (
            <IconButton aria-label="menu" onClick={toggleMenu}>
              <MenuIcon fontSize="large" />
            </IconButton>
          )
        }

        <div style={{ width: '100%' }}>
          <ExitWarning />
        </div>
      </header>

      {
        isMobile && (
          <SwipeableDrawer
            anchor="bottom"
            open={isMenuOpen}
            onClose={closeMenu}
            onOpen={openMenu}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
          >
            <List>
              <ListItem disabled>
                <ListItemIcon><PriorityHighIcon /></ListItemIcon>
                <ListItemText primary="Kamua is currently not yet available on mobile." />
              </ListItem>

              <Divider />

              <ListItem button component="a" href="https://status.kamua.com/">
                <ListItemIcon><FiberManualRecordIcon /></ListItemIcon>
                <ListItemText primary="Status" />
              </ListItem>
              <ListItem button component="a" href="https://kamua.com/beta/app/pricing">
                <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                <ListItemText primary="Pricing" />
              </ListItem>
              <ListItem button component="a" href="https://kamua.com/blog/">
                <ListItemIcon><DescriptionIcon /></ListItemIcon>
                <ListItemText primary="Blog" />
              </ListItem>

              <ListItem button component="a" href="https://help.kamua.com/">
                <ListItemIcon><HelpIcon /></ListItemIcon>
                <ListItemText primary="Knowledge Base" />
              </ListItem>
              <Divider />

              <ListItem button component="a" href="https://kamua.com/blog/cookie-policy/" target="_blank">
                <ListItemIcon><SecurityIcon /></ListItemIcon>
                <ListItemText primary="Privacy Policy" />
              </ListItem>
              <ListItem button component="a" href="https://kamua.com/blog/cookie-policy/" target="_blank">
                <ListItemIcon><HttpsIcon /></ListItemIcon>
                <ListItemText primary="Cookie Usage" />
              </ListItem>
              <Divider />
              <ListItem button component="a" href="https://discord.gg/Z6ZSW8b333" target="_blank">
                <ListItemIcon>
                  <img alt="Discord" src="/icons/social/discord-icon.png" width="24" height="24" />
                </ListItemIcon>
                <ListItemText primary="Join our Discord channel!" />
              </ListItem>
            </List>
          </SwipeableDrawer>
        )
      }

      {
        ConfirmationStore.showLogin && (
          <LoginModal />
        )
      }

    </>
  );
}

export default observer(Header);
