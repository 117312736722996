import React, { Component } from 'react';

import { Button } from '@material-ui/core';
import triggerLoginWithFacebook from '../../../functions/facebookLogin';
import classes from './LoginWithFacebook.module.scss';
import { ReactComponent as FacebookIcon } from './facebook-ico.svg';
import LoginStore from '../../../stores/LoginStore';

export default class LoginWithFacebookButton extends Component<{}> {
  handleLoginWithFacebook = () => {

    triggerLoginWithFacebook(facebookToken => {
      LoginStore.login({ facebookToken });
    });
  };

  render() {
    return (
      <Button
        className={classes.root}
        variant="contained"
        color="primary"
        startIcon={<FacebookIcon />}
        onClick={this.handleLoginWithFacebook}
      >
        Login with Facebook
      </Button>
    );
  }
}
