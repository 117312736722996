import React from 'react';
import classes from './DividerWithText.module.scss';

type Props = {
  children: React.ReactNode,
};
function DividerWithText(props: Props) {
  const { children } = props;

  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <span className={classes.content}>
        {children}
      </span>
      <div className={classes.border} />
    </div>
  );
}

export default React.memo(DividerWithText);
