import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { InputAdornment, IconButton, TextField } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CloseIcon from '@material-ui/icons/Close';

import { formatError } from '../../functions/errors';
import { validEmail } from '../../functions/helpers';
import { RequestState } from '../../functions/requestState';
import AlertStore from '../../stores/AlertStore';
import LoginStore from '../../stores/LoginStore';
import { eventList } from '../../tracking/events';
import { LoaderButton } from '../atoms/LoaderButton';
import LoginWithApple from '../atoms/LoginWithApple';
import LoginWithGoogle from '../atoms/LoginWithGoogle';
import classes from './LoginPage.module.scss';
import LoginWithFacebookButton from './social/LoginWithFacebook';
import type { InputChangeType } from './LoginPage';

function preventDefault(event: React.SyntheticEvent<any>) {
  event.preventDefault();
}

type Props = {
  email: string,
  password: string,

  isActive: boolean,
  showPassword: boolean,
  recoveryState: RequestState<any>,
  showGoogle: boolean,
  showForgotPass?: boolean,

  handleChangeEmail: (e: InputChangeType) => void,
  handleChangePassword: (e: InputChangeType) => void,
  handleShowPassword: (e: React.MouseEvent<any>) => void,

  handleForgotClick?: () => void,
  handleLoginClick?: () => void,

  className?: string,
};

function LoginComponent(props: Props) {
  const { loading, error } = LoginStore;
  const {
    isActive, showPassword, recoveryState,
    showGoogle,
    email, handleChangeEmail,
    password, handleChangePassword, handleShowPassword,
    handleForgotClick, handleLoginClick,
  } = props;
  const recoveryError = recoveryState.error;

  const [showForget, setShowForget] = React.useState<boolean>(false);

  let showForgotPass = showForget;
  if (props.showForgotPass !== undefined) {
    showForgotPass = props.showForgotPass;
  }

  let setShowForgotPass = React.useCallback(() => {
    setShowForget(!showForget);
  }, [showForget, setShowForget]);

  if (handleForgotClick) {
    setShowForgotPass = handleForgotClick;
  }

  let setHideForgotPass = setShowForgotPass;
  if (handleLoginClick) {
    setHideForgotPass = handleLoginClick;
  }

  const handleSendRecoveryLink = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (recoveryState.loading) return;

    const data = new FormData(e.currentTarget);
    const recoveryEmail = data.get('email') as string;

    if (!validEmail(recoveryEmail)) {
      AlertStore.showError('Invalid email address');
      return;
    }

    eventList.user.sendRecoveryEmail();
    LoginStore.requestPasswordReset(recoveryEmail, recoveryState);
  }, [recoveryState]);

  const handleLoginSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (LoginStore.loading) { return; }

    LoginStore.login({ email, pass: password, register: false });
  }, [LoginStore.loading, email, password]);

  const endPassIcon = React.useMemo(() => ({
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleShowPassword}
          onMouseDown={preventDefault}
          size="small"
        >
          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </InputAdornment>
    ),
  }), [showPassword, handleShowPassword]);

  return (
    <section className={classNames(classes.body, isActive && classes.bodyActive, props.className)}>
      <nav className={classes.social}>
        <LoginWithApple />
        <LoginWithFacebookButton />
        <LoginWithGoogle isVisible={showGoogle} />
      </nav>

      <span className={classes.separator}>
        or
        {error && <div className={classes.loginError}>{formatError(error)}</div>}
      </span>

      <form
        className={classes.form}
        onSubmit={handleLoginSubmit}
      >
        <TextField
          className={classes.textfield}
          fullWidth
          margin="normal"
          value={email}
          onChange={handleChangeEmail}
          placeholder="Your email"
          type="email"
          name="username"
          autoFocus={isActive}
          autoComplete="section-login username"
          required
        />

        <div className={classes.passwordInput}>
          <TextField
            className={classes.textfield}
            fullWidth
            margin="normal"
            value={password}
            onChange={handleChangePassword}
            placeholder="Your password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            autoComplete="section-login current-password"
            inputProps={{ minLength: 6 }}
            /* eslint-disable-next-line react/jsx-no-duplicate-props */
            InputProps={endPassIcon}
            required
          />
        </div>

        <LoaderButton
          className={classes.formButton}
          loading={loading}
          variant="contained"
          color="primary"
          type="submit"
        >
          Login
        </LoaderButton>
      </form>

      <span
        tabIndex={0}
        role="button"
        className={classes.info}
        onClick={setShowForgotPass}
      >
        <u>Forgot your password?</u>
      </span>

      <div className={classNames(classes.modal, showForgotPass && classes.modalActive)}>
        <IconButton
          className={classes.onClose}
          onClick={setHideForgotPass}
        >
          <CloseIcon />
        </IconButton>

        <div className={classes.modalBody}>
          <h3 className={classes.modalBodyTitle}>Forgot your password?</h3>
          <h4 className={classes.modalBodySubTitle}>{'Please enter your account’s email address and '
          + 'we will send you a link and instructions to reset your password.'}
          </h4>

          <form className={classes.form} onSubmit={handleSendRecoveryLink}>
            <TextField
              className={classes.textfield}
              fullWidth
              placeholder="Enter your email address"
              type="email"
              name="email"
              required
            />

            <LoaderButton
              className={classes.formButton}
              loading={recoveryState.loading}
              type="submit"
              variant="contained"
              color="primary"
            >
              Send Recovery Link
            </LoaderButton>
          </form>
          {recoveryError && <div className={classes.error}>{formatError(recoveryError)}</div>}
        </div>
      </div>
    </section>
  );
}

export default React.memo(observer(LoginComponent));
