import React, { PureComponent } from 'react';

import logoUrl from './kamua-logo-600.png';
import classes from './Logo.module.scss';

const eyeRadius = 1.5;
const socketX = 20.5;
const socketY = 9.25;
const socketRadius = 1.5;

function getTransform(directionX: number, directionY: number) {
  let x = directionX;
  let y = directionY;

  const len = Math.sqrt(x * x + y * y);
  if (len > socketRadius) {
    const scale = socketRadius / len;
    x *= scale;
    y *= scale;
  }

  return `translate(${x + socketX - eyeRadius}px, ${y + socketY - eyeRadius}px)`;
}

class Logo extends PureComponent<{}> {
  root: HTMLDivElement | null = null;

  eye: HTMLDivElement | null = null;

  componentDidMount() {
    window.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleMouseMove = (evt: MouseEvent) => {
    const { root, eye } = this;
    if (!root || !eye) { return; }

    const x = evt.clientX - (root.getBoundingClientRect().left + socketX);
    const y = evt.clientY - (root.getBoundingClientRect().top + socketY);

    eye.style.transform = getTransform(x, y);
  };

  handleRef = (ref: HTMLDivElement | null) => { this.root = ref; };

  handleEyeRef = (ref: HTMLDivElement | null) => { this.eye = ref; };

  render() {
    return (
      <div className={classes.root} ref={this.handleRef}>
        <img src={logoUrl} alt="Home" className={classes.logo} />
        <div className={classes.eyePatch} />
        <div
          ref={this.handleEyeRef}
          className={classes.eye}
          style={{ transform: getTransform(-1, 0) }}
        />
      </div>
    );
  }
}

export default Logo;
