import * as paths from '../paths';
import { EasingType } from './JobData';

// dev
let withProxy = (process.env.REACT_APP_WITHPROXY === 'true')
  ? ((prod: string, relative: string) => relative)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  : ((prod: string, relative: string) => prod);

if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  withProxy = ((prod, relative) => prod);
}

const API = withProxy(paths.API, '/proxy/api');
const MEDIA = withProxy(paths.MEDIA, '/proxy/media');
const RENDERED = withProxy(paths.RENDERED, '/proxy/rendered');
const CLOUDFLARE = withProxy(paths.CLOUDFLARE, '/proxy/cloudflare');
const UPLOAD = withProxy(paths.UPLOAD, '/proxy/upload');
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GIF = withProxy(paths.UPLOAD, '/proxy/upload');

const CONCURRENCY = 10;
const STATUS_UPDATE_INTERVAL = 2 * 1000;
const FAST_STATUS_UPDATE_INTERVAL = 0.5 * 1000;
const FAST_STATUS_UPDATE_TRESHOLD = 0.8;

const unstableStateComputeOverallProgress = (
  status: string, progress: number, UNSTABLE_STATES: { status: string[], cost: number }[],
) => {
  const fullCost = UNSTABLE_STATES.reduce((a, c) => a + c.cost, 0);
  let statusCost = 0;
  let i;
  for (i = 0; i < UNSTABLE_STATES.length; i += 1) {
    const step = UNSTABLE_STATES[i];
    if (~step.status.indexOf(status)) break;
    statusCost += step.cost;
  }
  const prog = progress || 0;
  if (i < UNSTABLE_STATES.length) {
    statusCost += UNSTABLE_STATES[i].cost * prog;
  }

  return statusCost / fullCost;
};

// fixme those really should pe Sets & Maps
const MEDIA_STABLE_STATES = ['done', 'errored', 'invalid'];
export const MEDIA_DETERMINATE_PROGRESS_STATES = ['downloading', 'uploading', 'preprocessing', 'processing'];
const MEDIA_UNSTABLE_STATES = [{
  status: ['waiting', 'waiting_user_file_upload', 'waiting_user_file_upload_external'],
  cost: 0,
}, {
  status: ['downloading', 'uploading'],
  cost: 10,
}, {
  status: ['downloaded', 'uploaded', 'verifying_input_file'],
  cost: 0,
}, {
  status: ['preprocessing'],
  cost: 3,
}, {
  status: ['preprocessed'],
  cost: 0,
}, {
  status: ['processing'],
  cost: 15,
}, {
  status: ['processed'],
  cost: 0,
}];

const mediaStatusToText = (status: string) => {
  switch (status) {
  case 'waiting':
    return 'Queued';
  case 'waiting_user_file_upload':
    return 'Waiting to upload';
  case 'waiting_user_file_upload_external':
    return 'Waiting to upload';
  case 'verifying_input_file':
    return 'Verifying file';
  case 'downloading':
  case 'downloaded':
    return 'Linking';
  case 'uploading':
  case 'uploaded':
    return 'Queued';
  case 'preprocessing':
  case 'preprocessed':
    return 'Preparing';
  case 'processing':
  case 'processed':
    return 'Processing';

  case 'done':
    return 'Ready';
  case 'invalid':
    return 'Invalid file';
  case 'errored':
    return 'Errored';

  default:
    return status;
  }
};

const mediaUnstableStateComputeOverallProgress = (
  status: string,
  progress: number,
) => (
  unstableStateComputeOverallProgress(
    status,
    progress,
    MEDIA_UNSTABLE_STATES,
  )
);

const JOB_STABLE_STATES = ['done', 'errored'];
const JOB_UNSTABLE_STATES = [{
  status: ['waiting'],
  cost: 0,
}, {
  status: ['locked'],
  cost: 0,
}, {
  status: ['rendering'],
  cost: 1,
}];

const jobStatusToText = (status: string) => {
  switch (status) {
  case 'waiting':
    return 'Queued';

  case 'locked':
  case 'rendering':
    return 'Processing...';

  case 'done':
    return 'Ready';
  case 'errored':
    return 'An error occured';

  default:
    return status;
  }
};

const jobUnstableStateComputeOverallProgress = (status: string, progress: number) => (
  unstableStateComputeOverallProgress(
    status,
    progress,
    JOB_UNSTABLE_STATES,
  )
);

export type AspectRatioNames = 'custom' | 'auto' | 'landscape' | 'cinematic' | 'square' | 'twitter' | 'photo' | 'facebook-cover' | 'portrait';

export type AspectRatioType = {
  [key in AspectRatioNames]: number | null;
};

const aspectRatioByName: Omit<AspectRatioType, 'custom'> = {
  auto: null,
  landscape: 16 / 9,
  cinematic: 2.39,
  square: 1,
  twitter: 5 / 4,
  photo: 4 / 5,
  'facebook-cover': 820 / 315,
  portrait: 9 / 16,
};

const nameOfAspectRatio: Map<number | null, string> = new Map(
  Object.entries(aspectRatioByName).map(([name, ar]) => [ar, name]),
);

const EASINGS: EasingType[] = ['linear', 'easeInQuad', 'easeOutQuad', 'easeInOutQuad', 'easeOutElastic'];

export {
  API,
  MEDIA,
  RENDERED,
  UPLOAD,
  CLOUDFLARE,

  CONCURRENCY,
  STATUS_UPDATE_INTERVAL,
  FAST_STATUS_UPDATE_INTERVAL,
  FAST_STATUS_UPDATE_TRESHOLD,

  MEDIA_STABLE_STATES,
  MEDIA_UNSTABLE_STATES,
  mediaUnstableStateComputeOverallProgress,
  mediaStatusToText,

  JOB_STABLE_STATES,
  JOB_UNSTABLE_STATES,
  jobUnstableStateComputeOverallProgress,
  jobStatusToText,

  aspectRatioByName,
  nameOfAspectRatio,

  EASINGS,
};
