import { DialogProps } from '@material-ui/core/Dialog/Dialog';
import React from 'react';
import Close from '@material-ui/icons/Close';
import { Dialog, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import classes from './Dialog.module.scss';

export type OnCloseType = {
  bivarianceHack(
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
    reason?: 'backdropClick' | 'escapeKeyDown' | 'XButtonClick'
  ): void;
}['bivarianceHack'];

type Props = DialogProps & {
  onClose: OnCloseType;
};

const SimpleDialog = ((props: Props) => {
  const { children, ...otherProps } = props;

  const XButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    otherProps.onClose(e, 'XButtonClick');
  };

  let hasAddedPaddingForX = false;
  return (
    <Dialog
      {...otherProps}
      // className={classes.dialog}
    >
      {React.Children.map(children, child => {
        if (!hasAddedPaddingForX && React.isValidElement(child)) {
          hasAddedPaddingForX = true;
          return React.cloneElement(
            child,
            {
              className: classNames(
                child.props.className,
                classes.rightPad,
              ),
            },
          );
        }
        return child;
      })}

      {/* {children} */}

      <IconButton className={classes.close} onClick={XButtonClick}>
        <Close />
      </IconButton>

    </Dialog>

  );
});

export default SimpleDialog;
