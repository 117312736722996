import { Button } from '@material-ui/core';
import AppleIcon from '@material-ui/icons/Apple';
import React from 'react';
import { isSignup } from '../../functions/helpers';
import { API } from '../../paths';
import { eventList } from '../../tracking/events';
import classes from '../login/LoginPage.module.scss';
import LoginStore from '../../stores/LoginStore';

function openAppleLoginWindow() {
  const appleWindow = window.open(
    `${API}/user/social/apple`,
    '_blank',
    'width=700,height=700,scrollbars=no,resizable=yes');

  if (!appleWindow) {
    return;
  }

  const checkLogin = setInterval(async () => {
    let windowLocation = '';

    try {
      windowLocation = appleWindow.location.href;
    } catch (err) {
      // noop
    }

    // Convert back to string in case the leprechauns played with it.
    windowLocation += '';

    if (windowLocation.includes('access_token')) {
      // Close the Twitter Window cause we no longer need it :D
      appleWindow.close();
      // Clear the Interval. We no longer need it.
      clearInterval(checkLogin);

      const accessToken = windowLocation.split('?access_token=')[1];

      await LoginStore.getUserData(accessToken);
      const data = LoginStore.data;

      if (!data) {
        console.error('Missing userData after Apple Login');
        return;
      }

      const whatToCall = isSignup(data) ? 'signUp' : 'login';
      eventList.global[whatToCall]({ method: 'apple' });
      if (whatToCall === 'signUp' && window.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }
    }
  }, 250);

  setTimeout(() => {
    // failed
    if (appleWindow) {
      appleWindow.close();
    }

    clearInterval(checkLogin);
  }, 120000);

}

function LoginWithApple() {
  return (
    <Button
      onClick={openAppleLoginWindow}
      className={classes.appleButton}
      startIcon={<AppleIcon />}
      variant="contained"
      color="default"
    >
      Login with Apple
    </Button>
  );
}

export default React.memo(LoginWithApple);
