import type { WordType } from 'aive-canvas-overlays';
import { CustomShots, MathHelpers, UtilHelpers } from 'aive-common';
import Fraction from 'fraction.js';
import cloneDeep from 'lodash.clonedeep';
import { toJS } from 'mobx';
import React, { RefObject } from 'react';

import { AiveFontsSubsetHelpers } from 'aive-fonts/dist/subsets';

import type { DropTargetMonitor, XYCoord } from 'react-dnd';
import naturalCompare from 'string-natural-compare';
import { inspect } from 'util';
import { LoginStoreType } from '../stores/LoginStore';

import type { FontSubset } from '../stores/Project';
import type { Shot } from '../components/project/editor/Shot';
import type {
  ItemDropDetailsData,
} from '../components/project/timeline/clipUtils';
import type { TimelineContextType } from '../components/project/timeline/TimelineContext';
import type {
  ClipWithIDs,
  InputAudioClipWithID,
  ImageOverlayClipWithID,
} from '../stores/ClipWithIDsType';
import type { Clip, KeyFrameTracks } from '../stores/JobData';
import type Media from '../stores/Media';
import type { CustomDTGShotChange, MediaData, MediaShot, StatusTypes } from '../stores/MediaTypes';
import type { PreviewClip } from '../stores/PreviewClipType';
import { eventList } from '../tracking/events';

import type { CancelToken } from './cancelPromise';
import { AUDIOCLIP, OVERLAY } from './dragTypes';

import { frac } from './fractions';
import toJSClone from './toJSClone';

export function isOverlayClip(
  item: PreviewClip | WordType | ImageOverlayClipWithID,
): item is ImageOverlayClipWithID {
  return 'preset' in item;
}

export type DragFreeItemType = {
  timelineID: number,
  index: number,
  timelineData: TimelineContextType,
  ref: RefObject<HTMLDivElement>,
};

export type DragOverlayItemType = DragFreeItemType & { type: typeof OVERLAY };
export type DragAudioItemType = DragFreeItemType & { type: typeof AUDIOCLIP };

export type OnDropFreeItemDataType<T extends ImageOverlayClipWithID | InputAudioClipWithID> = {
  item: T extends ImageOverlayClipWithID ? DragOverlayItemType : DragAudioItemType,
  currentOffset: XYCoord,
  clientOffset: XYCoord,
  itemDropDetails: ItemDropDetailsData,
} | null;

export const MAX_SAFE_INTEGER = (2 ** 53) - 1; // 9007199254740991
const bytesUnitList = ['B', 'KB', 'MB', 'GB'];
const bitsUnitList = ['b', 'Kb', 'Mb', 'Gb'];

export const metricToHumanReadable = ({
  number,
  list = bytesUnitList,
  magnitude = 2 ** 10,
  precision = 2,
}: {
  number: number,
  list?: string[],
  magnitude?: number,
  precision?: number,
}) => {

  let index = Math.floor(
    Math.log(number) / Math.log(magnitude),
  );
  index = Math.max(0, Math.min(index, list.length - 1));

  const name = list[index];

  return `${(number / (magnitude ** index)).toFixed(precision).replace(/\.0+$/, '')} ${name}`;
};

export const timestampSourceToPreview = (
  sourceTime: number,
  clip: any, // PreviewClip - Stop Dependency Cycles
): number => (sourceTime - clip.clip.begin) / clip.speed + clip.timelineBegin;

export const timestampPreviewToSource = (
  previewTime: number,
  clip: any, // PreviewClip - Stop Dependency Cycles
): number => (
  (previewTime - clip.timelineBegin) * clip.speed + clip.clip.begin
);

export const estimateErroredClipDuration = (
  clip: any, // ClipWithIDs - Stop Dependency Cycles
) => {
  const { begin, end, beginIndex, endIndex } = clip;
  if (begin === end || beginIndex === endIndex) {
    return end - begin;
  }
  const frameInterval = (end - begin) / (endIndex - beginIndex);
  return end - begin + frameInterval;
};

export const bytesToString = (number: number, precision: number = 2) => metricToHumanReadable({
  number, list: bytesUnitList, precision,
});
export const bitsToString = (number: number, precision: number = 2) => metricToHumanReadable({
  number, list: bitsUnitList, precision,
});

export const ucFirst = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const bytesToMBGroups = (size: number) => {
  const sizeInMB = Math.round(size * 1e-6);
  let group = '';

  if (sizeInMB < 100) {
    group = '<100MB';
  } else if (sizeInMB < 250) {
    group = '100MB - 250MB';
  } else if (sizeInMB < 500) {
    group = '250MB - 500MB';
  } else if (sizeInMB < 1000) {
    group = '500MB - 1GB';
  } else if (sizeInMB < 5000) {
    group = '1GB - 5GB';
  } else if (sizeInMB < 10000) {
    group = '5GB - 10GB';
  } else if (sizeInMB > 10000) {
    group = '>10GB';
  }

  return group;
};

export const secondsToString = (seconds: number, showHours: boolean = false) => {
  let sec = Math.round(seconds);

  const minus = seconds < 0;
  if (minus) {
    sec = -sec;
  }

  let minutes = Math.floor(sec / 60);
  sec %= 60;

  const hours = Math.floor(minutes / 60);
  minutes %= 60;

  const secondsString = sec.toString().padStart(2, '0');
  const minutesString = minutes.toString().padStart(2, '0');

  let result = (hours || showHours)
    ? `${hours}:${minutesString}:${secondsString}`
    : `${minutesString}:${secondsString}`;

  if (minus) {
    result = `-${result}`;
  }

  return result;
};

export const secondsToStringFractional = (
  seconds: number,
  digits: number = 2,
  showHours: boolean = false,
  useComma: boolean = false,
) => {
  let sec = seconds;

  const minus = seconds < 0;
  if (minus) {
    sec = -sec;
  }

  const integer = Math.floor(sec);
  const fractional = Math.floor((sec - integer) * (10 ** digits)).toString().padStart(digits, '0');

  let result = `${secondsToString(integer, showHours)}${useComma ? '.' : ','}${fractional}`;
  if (minus) {
    result = `-${result}`;
  }

  return result;
};

export const secondsToStringLong = (seconds: number) => {
  let sec = Math.round(seconds);

  const minus = seconds < 0;
  if (minus) {
    sec = -sec;
  }

  let minutes = Math.floor(sec / 60);
  sec %= 60;

  const hours = Math.floor(minutes / 60);
  minutes %= 60;

  return ([
    !!hours && `${hours} hours`,
    !!minutes && `${minutes} minutes`,
    !!sec && `${sec} seconds`,
  ]).filter(x => !!x).join(' ') || '0 seconds';
};

export const secondsToStringMedium = (seconds: number) => {
  let sec = Math.round(seconds);

  const minus = seconds < 0;
  if (minus) {
    sec = -sec;
  }

  let minutes = Math.floor(sec / 60);
  sec %= 60;

  const hours = Math.floor(minutes / 60);
  minutes %= 60;

  return ([
    !!hours && `${hours}h`,
    !!minutes && `${minutes}m`,
    !!sec && `${sec}s`,
  ]).filter(x => !!x).join(' ') || '0s';
};

export const getResolutionName = (height: number) => {
  if (height >= 4320) {
    return '8k';
  }
  if (height >= 2160) {
    return 'uhd';
  }
  if (height >= 1080) {
    return 'fhd';
  }
  if (height >= 720) {
    return 'hd';
  }
  return 'sd';
};

export const delay = <T>(timeout: number, cancelToken?: CancelToken<T>) => (
  new Promise<void>(resolve => {
    const handle = setTimeout(() => {
      resolve();
    }, timeout);
    if (cancelToken) {
      cancelToken.promise.then(() => {
        clearTimeout(handle);
      });
    }
  })
);

export function rafThrottle<T extends (...args: any[]) => any>(f: T): (T & {
  flush: () => void,
}) {
  let rafHandle: number | null = null;
  let nextArgs: Nullable<any[]> = null;

  const rafCallback = () => {
    rafHandle = null;
    if (nextArgs) {
      const args = nextArgs;
      nextArgs = null;
      f(...args);
    }
  };

  const throttledFunc = (...args: any[]) => {
    if (rafHandle) {
      nextArgs = args;
    } else {
      f(...args);
      rafHandle = requestAnimationFrame(rafCallback);
    }
  };

  throttledFunc.flush = () => {
    if (rafHandle) {
      cancelAnimationFrame(rafHandle);
      rafCallback();
    }
  };

  return throttledFunc as T & { flush: () => void };
}

export function preventScrollbarFlicker(componentInstance: any, width: number): number {
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  let _scrollbarWidth = componentInstance._scrollbarWidth || 0;
  if (scrollbarWidth > _scrollbarWidth) {
    _scrollbarWidth = scrollbarWidth;
    componentInstance._scrollbarWidth = _scrollbarWidth;
  }
  return width + scrollbarWidth - _scrollbarWidth;
}

export type Map2D<K1, K2, V> = Map<K1, Map<K2, V>>;

export function getMap2D<K1, K2, V>(map: Map2D<K1, K2, V>, key1: K1, key2: K2): V | undefined {
  const intermediate = map.get(key1);
  if (!intermediate) {
    return undefined;
  }
  return intermediate.get(key2);
}

export function setMap2D<K1, K2, V>(map: Map2D<K1, K2, V>, key1: K1, key2: K2, value: V) {
  let intermediate = map.get(key1);
  if (!intermediate) {
    map.set(key1, new Map());
    intermediate = map.get(key1);
  }
  if (!intermediate) {
    return;
  }
  intermediate.set(key2, value);
}

export function delMap2D<K1, K2, V>(map: Map2D<K1, K2, V>, key1: K1, key2: K2): boolean {
  const intermediate = map.get(key1);
  if (!intermediate) {
    return false;
  }
  return intermediate.delete(key2);
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function boundsCheck<T>(array: T[], index: number, length: number) {
  return ((index >= 0 && index < length)
    ? array[index]
    : undefined
  );
}

export function applyShotChanges(
  originalShots: MediaShot[],
  changes: CustomDTGShotChange[],
  dtgVersion: string,
) {

  return CustomShots.applyShotChanges({
    originalShots,
    changes,
    dtgVersion,
  });

}

export function arrayMove<T>(array: T[], srcIndex: number, dstIndex: number): Array<T> {
  if (srcIndex < 0 || dstIndex < 0 || srcIndex === dstIndex) {
    return array;
  }
  const { length } = array;
  if (srcIndex >= length || dstIndex > length) {
    return array;
  }
  const newArray = array.slice();
  const value = newArray.splice(srcIndex, 1)[0];
  // if we remove a value from before the index we want to move it to
  // that means all indexes are now with 1 smaller.
  const newDstIndex = srcIndex < dstIndex ? dstIndex - 1 : dstIndex;
  newArray.splice(newDstIndex, 0, value);
  return newArray;
}

export function getDefault<T>(value: Maybe<T>, defaultValue: T): T {
  return (value === undefined || value === null) ? defaultValue : value;
}

export function getDefaultMaybe<T>(value: Maybe<T>, defaultValue: Maybe<T>): Maybe<T> {
  return (value === undefined || value === null) ? defaultValue : value;
}

export function isMouseInFirstHalfOfElem(ele: HTMLDivElement, monitor: DropTargetMonitor) {
  const hoverBoundingRect = ele.getBoundingClientRect();
  const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

  // Determine mouse position
  const clientOffset = monitor.getClientOffset();

  if (clientOffset === null) return null;

  // Get pixels to the top
  const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

  return hoverClientX < hoverMiddleX;
}

export function deepCloneClip(clip: ClipWithIDs): ClipWithIDs {
  return toJSClone(clip);
}

export function deepClonePreviewClip(previewClip: PreviewClip): PreviewClip {
  return toJSClone(previewClip);
}

export type Region = {
  'beginIndex': number,
  'endIndex': number,
};

export function isPartOfRegion(
  what: Region,
  region: Region,
) {
  return ((region.beginIndex <= what.beginIndex && what.beginIndex <= region.endIndex)
    || (region.beginIndex <= what.endIndex && what.endIndex <= region.endIndex));
}

export function isShotPartOfClip(shot: MediaShot, clip: Clip) {
  return (
    (clip.beginIndex <= shot.beginIndex && shot.beginIndex <= clip.endIndex)
    || (clip.beginIndex <= shot.endIndex && shot.endIndex <= clip.endIndex)
    || (shot.beginIndex <= clip.beginIndex && clip.beginIndex <= shot.endIndex)
    || (shot.endIndex <= clip.endIndex && clip.endIndex <= shot.endIndex)
  );
}

export function equalToShot(shot: Shot) {
  const { id } = shot;
  return (s: Shot) => id === s.id;
}

// patch function
// what we want is to delete / add / place values while keeping as many
// refs as intact as possible - so mobx won't trigger reactions.
export function replaceObjectKeepRefs(original: any, cover: any, omitKeys?: string[]) {
  const originalKeys = Object.keys(original);
  const coverKeys = Object.keys(cover);
  // console.trace('replaceObjectKeepRefs');
  // we delete all keys that no longer exist.
  // we reverse so when we start deleting keys from the object
  // we can delete all.
  originalKeys.reverse().forEach(key => {
    if (!coverKeys.includes(key)) {

      // if we're an array and we need to delete an index.
      // we cannot check with isArray or instanceof because of mobx observable arrays
      // eslint-disable-next-line no-restricted-globals
      if (original.length && !isNaN(Number(key))) {
        (original as any[]).splice(Number(key), 1);
      } else {
        // we're probably in an object.
        delete original[key];
      }
    }
  });

  // we go through all the new object
  // and we add all the new keys to the original object.
  coverKeys.forEach(key => {
    if (omitKeys && omitKeys.includes(key)) {
      // key needs to be omitted.
      return;
    }

    if (original[key] && cover[key] && typeof cover[key] === 'object' && !(cover[key] instanceof Date)) {
      replaceObjectKeepRefs(original[key], cover[key], omitKeys);
    } else if (typeof cover[key] === 'object') {
      // we want to keep original refs not cover ref;
      // if new object doesn't exist in original
      original[key] = cloneDeep(toJS(cover[key]));
    } else {
      // update properties if they're not objects
      original[key] = cover[key];
    }
  });
}

// merge object deep
// original object being a complex object
// cover being something to replace deep like { a: { b: { c: 'red' } } }
// leaving all other keys intact.
export function objectMergeDeep<T>(original: T, cover: DeepPartial<T>) {
  // it's better to push in array values that are beyond an array length
  // for mobx observable arrays.
  let pushInArray = false;
  if (original instanceof Array && cover instanceof Array && original.length !== cover.length) {
    if (cover.length < original.length) {
      // we need to remove some values from the array.
      original.splice(cover.length - 1, (original.length - cover.length));
    } else {
      // need to push values.
      pushInArray = true;
    }
  }
  const coverKeys: (keyof T)[] = Object.keys(cover) as (keyof T)[];
  coverKeys.forEach(key => {
    if (original[key] && cover[key] && typeof cover[key] === 'object' && !(cover[key] instanceof Date)) {
      objectMergeDeep(original[key], <T[keyof T]>cover[key]);
    } else if (pushInArray && original instanceof Array && key >= original.length) {
      original.push(cover[key]);
    } else {
      // update properties if they're not objects
      // fixme this break the assumption of type T
      //       suppose you have type A = {a?: {b: number, c: number}}
      //       and you objectMergeDeep({}, {a: {b: 1}})
      //       when checking key `a` in enters this branch
      //       and set `a` as `{b: 1}` but `a` sould be {b: number, c: number}
      //       thus breaking the type's requirement assumption
      // @ts-ignore
      original[key] = cover[key];
    }
  });
}

export function objectMergeDeepCloned<T>(original: T, cover: DeepPartial<T>) {
  const clone = UtilHelpers.unsafeDeepClone(original);
  objectMergeDeep(clone, cover);
  return clone;
}

export function range(start: number, end: number, step: number = 1, inclusive = false): number[] {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _end = end + (inclusive ? step : 0);

  const arr: number[] = [];
  if (step > 0) {
    for (let i = start; i < _end; i += step) {
      arr.push(i);
    }
  } else {
    for (let i = start; i > _end; i += step) {
      arr.push(i);
    }
  }
  return arr;
}

export function looseRange(boundA: number, boundB: number, step: number, inclusive = false) {
  const min = boundA < boundB ? boundA : boundB;
  const max = boundA > boundB ? boundA : boundB;
  return range(min, max, step, inclusive);
}

export function clamp(min: number, value: number, max: number): number {
  if (Number.isNaN(value)) return min;
  if (min > value) return min;
  if (max < value) return max;
  return value;
}

export function objectKeys<T extends object>(value: T): (keyof T)[] {
  return Object.keys(value) as (keyof T)[];
}

export function looseBinarySearch(arr: number[], item: number): { index: number, value: number } {

  let min = 0;
  let max = arr.length - 1;

  let idx: number;
  let val: number;

  while (max - min > 1) {
    idx = Math.floor((max + min) / 2);
    val = arr[idx];

    if (item < val) {
      max = idx;
    }
    if (item > val) {
      min = idx;
    }
    if (item === val) {
      return { value: val, index: idx };
    }
  }

  // at this point we are exactly 2 items left
  const deltaMin = item - arr[min];
  const deltaMax = arr[max] - item;

  if (deltaMin < deltaMax) {
    return { value: arr[min], index: min };
  }
  return { value: arr[max], index: max };

}

export function strictBinarySearch(
  arr: number[],
  item: number,
): { index: number, value: number } | null {
  const data = looseBinarySearch(arr, item);
  if (data.value === item) return data;
  return null;
}

export function computeTimelineNumbers(
  item: PreviewClip | WordType | ImageOverlayClipWithID, // | InputAudioClipWithID,
  timelineData: TimelineContextType,
) {
  const {
    paddingRight, viewportWidth, totalDuration,
    paddingLeft, viewportScale, viewportOrigin,
  } = timelineData;

  let timelineBegin;
  let timelineEnd;

  let sourceBegin;
  let sourceEnd;

  // if (isAudioClip(clip)) {
  //   ({ begin: timelineBegin, end: timelineEnd } = clip);
  //   ({ begin: sourceBegin, end: sourceEnd } = clip.options.trim);
  if (isOverlayClip(item)) {
    timelineBegin = item.begin;
    timelineEnd = item.end;
    sourceBegin = item.begin;
    sourceEnd = item.end;
  } else if ('startTime' in item) {
    const wordDuration = item.endTime - item.startTime;
    timelineBegin = item.startTime;
    timelineEnd = item.endTime;
    sourceBegin = item.clipBegin || 0; // clipBegin always exist lol
    sourceEnd = sourceBegin + wordDuration;
  } else {
    ({ timelineBegin, timelineEnd } = item);
    ({ begin: sourceBegin, end: sourceEnd } = item.clip);
  }

  // at least 1 frame at 60fps
  const width = ((timelineEnd - timelineBegin) || 0.016) * viewportScale;

  const min = Math.max(-paddingLeft, (-viewportOrigin) * viewportScale);
  const max = Math.min(
    viewportWidth + paddingRight, (totalDuration - viewportOrigin) * viewportScale,
  );
  const viewportBegin = min / viewportScale + viewportOrigin;
  const viewportEnd = max / viewportScale + viewportOrigin;

  const clipInViewport = (
    // clip begin between begin and end
    (viewportBegin <= timelineBegin && timelineBegin <= viewportEnd)
    // clip end between begin and end
    || (viewportBegin <= timelineEnd && timelineEnd <= viewportEnd)
    // clip contains begin and end.
    || (timelineBegin <= viewportBegin && timelineEnd >= viewportEnd)
  );

  // we need to compute the visible duration
  let visibleStart = timelineBegin;
  let visibleEnd = timelineEnd;

  if (clipInViewport) {
    // the start is in view, we need to trim the end
    if (timelineEnd > viewportEnd) {
      // we trim the end
      visibleEnd = viewportEnd;
    }

    // the end is in the viewport
    if (timelineBegin < viewportBegin) {
      // we trim the begin
      visibleStart = viewportBegin;
    }
  }

  const visibleDuration = visibleEnd - visibleStart;
  const newItemBegin = sourceBegin + (visibleStart - timelineBegin);
  const newItemEnd = sourceEnd - (timelineEnd - visibleEnd);

  const left = paddingLeft + (timelineBegin - viewportOrigin) * viewportScale;

  const visibleWidth = visibleDuration * viewportScale;
  // how much left is needed so that it's visible.
  const leftUntilVisibility = (visibleStart - timelineBegin) * viewportScale;

  return {
    width, // total width of element in timeline
    left, // start point (absolute) in timeline container
    clipInViewport, // is clip currently visible?
    visibleWidth, // width in viewport
    leftUntilVisibility, // pixels between start point in timeline and until the visible part
    newItemBegin, // begin of visible part
    newItemEnd, // end of visible part - used for audio waveforms both VideoClipSegment & AudioClip
  };
}

export function stopPropagation(
  e: React.SyntheticEvent<HTMLElement> | React.MouseEvent | React.KeyboardEvent
  | MouseEvent | KeyboardEvent,
) {
  e.stopPropagation();
  if ('nativeEvent' in e) {
    e.nativeEvent.stopImmediatePropagation();
  }
}

export function preventDefaultAndStopPropagation(
  e: React.MouseEvent | React.SyntheticEvent<HTMLElement> | React.KeyboardEvent
  | MouseEvent | KeyboardEvent,
) {
  e.preventDefault();
  e.stopPropagation();
  if ('nativeEvent' in e) {
    e.nativeEvent.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  }
}

export function lerp(from: number, to: number, alpha: number): number {
  return from + alpha * (to - from);
}

export function inversLerp(from: number, to: number, value: number): number {
  const r = to - from;
  const valueInRange = value - from;
  return valueInRange / r;
}

export function maxZoomWhereMediaFitsInsideContainer(
  mediaAR: number | Fraction, containerAR: number | Fraction,
): number {
  return frac(containerAR).div(frac(mediaAR)).valueOf();
}

export const round = MathHelpers.round;

export function keyClamp(key: keyof KeyFrameTracks, value: number) {
  // value can be between 0 and 1 except for zoom which can be between 0 and 100.
  const maxValue = key === 'zoom' ? 100 : 1;
  return clamp(0, value, maxValue);
}

export function between<T extends number | string>(min: T, value: T, max: T): boolean {
  return value >= min && value <= max;
}

export function looseBetween(boundA: number, value: number, boundB: number): boolean {
  const min = boundA < boundB ? boundA : boundB;
  const max = boundA > boundB ? boundA : boundB;

  return between(min, value, max);
}

export function findLast<T>(array: T[], predicate: (arg: T) => boolean) {
  for (let i = array.length - 1; i >= 0; i--) {
    const x = array[i];
    if (predicate(x)) {
      return x;
    }
  }
  return undefined;
}

export function fetchMediaIfNeeded(media: Media) {
  if (!media.data && !media.loading && !media.keepsUpdating) {
    media.fetch(true);
  }
}

export function fetchUnloadedMedia(mediaList: Media[]) {
  mediaList.forEach(fetchMediaIfNeeded);
}

export const validEmail = (email: string) => email.match('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');

type BoundingBox = { [s in 'top' | 'left' | 'bottom' | 'right']: number };

export function boundingBoxesIntersect(a: BoundingBox, b: BoundingBox): boolean {
  return (
    (
      (
        b.top <= a.top && a.top <= b.bottom
      ) || (
        b.top <= a.bottom && a.bottom <= b.bottom
      )
    ) && (
      (
        b.left <= a.left && a.left <= b.right
      ) || (
        b.left <= a.right && a.right <= b.right
      )
    )
  );
}

export function noop(/* noop */) {
  /* noop */
}

export const forceRefreshPage = () => window.location.reload();

export function isTimelineSorted(timeline: (ImageOverlayClipWithID | InputAudioClipWithID)[]) {
  let sorted = true;

  for (let i = 0; i < timeline.length - 1; i++) {
    if (timeline[i].begin > timeline[i + 1].begin) {
      sorted = false;
      break;
    }
  }

  return sorted;
}

export function handleContactUs() {
  eventList.global.clickedContactUs();
  if (!window.HubSpotConversations) {
    window.location.href = 'mailto:hello@kamua.com';
    return;
  }

  if (window.location.href.includes('/beta/app') && window.handleHelpClick) {
    window.handleHelpClick();
  } else {
    // Force the widget to open
    window.HubSpotConversations.widget.open();
  }
}

export function arrayGroupBy<T extends object, K extends keyof T>(
  input: T[],
  by: K,
): Map<T[K], T[]> {
  const map = new Map<T[K], T[]>();

  for (let i = 0; i < input.length; i++) {
    const e = input[i];
    const k = e[by];
    if (map.has(k)) {
      map.get(k)!.push(e);
    } else {
      map.set(k, [e]);
    }
  }

  return map;
}
export function objectGroupBy<INPUT extends Record<string, T>, T extends object, K extends keyof T>(
  input: INPUT,
  by: K,
): Map<T[K], Partial<INPUT>> {

  const inputKeys = objectKeys(input);

  const map = new Map<T[K], Partial<INPUT>>();

  for (let i = 0; i < inputKeys.length; i++) {
    const inputKey = inputKeys[i];
    const e = input[inputKey];
    const k = e[by];

    if (!map.has(k)) {
      map.set(k, { });
    }
    Object.assign(map.get(k)!, { [inputKey]: e });
  }

  return map;
}

export function progressToText(
  progress: number,
  below0: string,
  inProgress: string,
  above1: string,
) {
  if (progress <= 0) return below0;
  if (progress >= 1) return above1;
  return inProgress;
}

export function formatCurrency(value: number, currency: 'USD' | 'EUR' | 'GBP') {
  // values always come as int, if it's not an int
  // eg we have a price of 49.99, we need to floor it.
  const newValue = Math.floor(value);

  if (Intl) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(newValue / 100);
  }

  const valueFrac = (newValue + 100).toString()
    .substr(-2);
  const intStr = newValue.toString();
  const int = intStr.substr(0, intStr.length - 2) || '0';

  const symbols = {
    USD: '$',
    EUR: '€',
    GBP: '£',
  };

  const currencyDisplay = symbols[currency] || currency;
  return `${currencyDisplay}${int}.${valueFrac}`;
}

const rtlRegExpTest = (() => {
  const ltrChars = 'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF'
    + '\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF';
  const rtlChars = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC';
  // eslint-disable-next-line no-misleading-character-class
  return new RegExp(`^[^${ltrChars}]*[${rtlChars}]`);
})();

export function isRTL(s: string) {
  return rtlRegExpTest.test(s);
}

export function findSubSetForString(s: string) {
  const subsetRanges = AiveFontsSubsetHelpers.subsetUnicodeRangesRegex;
  const subsetsNeeded: Set<FontSubset> = new Set();

  Object.keys(subsetRanges).forEach(subset => {
    if (subsetRanges[subset].test(s)) {
      subsetsNeeded.add(subset);
    }
  });

  return subsetsNeeded;
}

type CustomErrorTypeDetails = {

  name?: string
  stack?: never
  message?: never

  [key: string]: any,
};

export class CustomError extends Error {
  name = 'CustomError';

  constructor(msg: string, details: CustomErrorTypeDetails) {
    super(msg);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }

    const flatDetails: CustomErrorTypeDetails = {};
    const keys = objectKeys(details);
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      const v = details[k];
      if (typeof v === 'object' && v !== null) {
        flatDetails[k] = inspect(v, { depth: 100, breakLength: 120, showHidden: true });
      } else {
        flatDetails[k] = v;
      }
    }

    Object.assign(this, flatDetails);
  }
}

export function getNewWords(currentWord: WordType, input?: HTMLInputElement) {
  // more than just one, let's add the rest as well.
  const currentWordDuration = currentWord.endTime - currentWord.startTime;
  const newWordsArray = (
    input ? input.value : currentWord.text
  ).split(' ').filter(el => !!el);

  const letterLength = newWordsArray.join('').length;
  const letterDuration = currentWordDuration / letterLength;

  let currentEndTime = currentWord.startTime;

  return newWordsArray.map(newWordText => {
    const newWord = JSON.parse(JSON.stringify(currentWord));
    newWord.text = newWordText;
    newWord.startTime = currentEndTime;
    newWord.endTime = MathHelpers.round(
      currentEndTime + letterDuration * newWordText.length,
      3,
    );
    currentEndTime = newWord.endTime;

    return newWord;
  });
}

export function isSignup(data: LoginStoreType) {
  const registeredTimePassed = (
    new Date().getTime() - new Date(data.user.timestamps.registered).getTime()
  ) / 1000;

  // isSignup if under 120s else login.
  return registeredTimePassed < 120;
}

export function roundTwo(number: number) {
  return Math.round(number * 100 + Number.EPSILON) / 100;
}

export function getMediaTimeItTookFor(status: StatusTypes, data: MediaData) {
  const changes = data.changes;

  const findStart = changes.findIndex(el => el.status === status);

  if (findStart === -1) return Infinity;

  const end = changes[findStart + 1]?.timestamp;

  if (!end) return Infinity;

  // convert to seconds.
  return roundTwo((end - changes[findStart].timestamp) / 1000);
}

export function sparseNumericSort<T, V extends number>(
  arr: T[],
  criteria: (item: T) => Maybe<V>,
  reverse: boolean,
): T[] {
  return arr.sort(
    (a, b) => {
      const _a = criteria(a);
      const _b = criteria(b);
      const sparse_a = _a === undefined || _a === null || Number.isNaN(_a);
      const sparse_b = _b === undefined || _b === null || Number.isNaN(_b);

      if (sparse_a && sparse_b) return 0;
      if (sparse_b) return -1;
      if (sparse_a) return +1;

      return (reverse ? -1 : 1) * (_a! - _b!);
    });
}

export function sparseStringSort<T, V extends string>(
  arr: T[],
  criteria: (item: T) => Maybe<V>,
  reverse: boolean,
): T[] {
  return arr.sort(
    (a, b) => {
      const _a = criteria(a);
      const _b = criteria(b);
      const sparse_a = _a === undefined || _a === null;
      const sparse_b = _b === undefined || _b === null;

      if (sparse_a && sparse_b) return 0;
      if (sparse_b) return -1;
      if (sparse_a) return +1;

      return (reverse ? -1 : 1) * naturalCompare(_a!, _b!);
    });
}

export function splitStringEvery(str: string, every: number, delimiter = '-') {
  return str.match(new RegExp(`.{1,${every}}`, 'g'))?.join(delimiter) ?? str;
}
