import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import Button, { ButtonProps } from '@material-ui/core/Button';

import { Tooltip } from '@material-ui/core';
import { useToggleOnOff } from '../../functions/helper-hooks';
import classes from './LoaderButton.module.scss';

type Props = ButtonProps & {
  loading?: Maybe<boolean>,
  tooltip?: string,
};

export function LoaderButton(props: Props) {
  const { loading, className, disabled, tooltip, ...other } = props;
  return (
    <Tooltip title={tooltip || ''}>
      <Button
        className={classNames(loading && classes.loading, className)}
        disabled={disabled || !!loading}
        {...other}
      />
    </Tooltip>
  );
}

type LoaderIconButtonProps = IconButtonProps & {
  loading?: Maybe<boolean>,
  tooltip?: string,
};
export function LoaderIconButton(props: LoaderIconButtonProps) {
  const { loading, className, disabled, tooltip, ...other } = props;
  return (
    <Tooltip title={tooltip || ''}>
      <div style={{ display: 'inline' }}>
        <IconButton
          className={classNames(loading && classes.loading, className)}
          disabled={disabled || !!loading}
          {...other}
        />
      </div>
    </Tooltip>
  );
}

type AutoLoaderButtonProps = Omit<Props, 'onClick' | 'loading'> & {
  onClick: (e: React.MouseEvent) => Promise<any>
};
export function AutoLoaderButton(props: AutoLoaderButtonProps) {
  const [loading, setLoading] = useToggleOnOff(false);

  const { onClick, ...otherProps } = props;

  const doClick = useCallback((e:React.MouseEvent) => {
    setLoading.on();
    const p = onClick(e);
    p.then(setLoading.off, setLoading.off);
  }, [onClick, setLoading]);

  return <LoaderButton loading={loading} onClick={doClick} {...otherProps} />;
}

type AutoLoaderIconButtonProps = Omit<LoaderIconButtonProps, 'onClick' | 'loading'> & {
  onClick: (e: React.MouseEvent) => Promise<any>
};
export function AutoLoaderIconButton(props: AutoLoaderIconButtonProps) {
  const [loading, setLoading] = useToggleOnOff(false);

  const { onClick, ...otherProps } = props;

  const doClick = useCallback((e:React.MouseEvent) => {
    setLoading.on();
    const p = onClick(e);
    p.then(setLoading.off, setLoading.off);
  }, [onClick, setLoading]);

  return <LoaderIconButton loading={loading} onClick={doClick} {...otherProps} />;
}
