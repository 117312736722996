/**
 Copyright Findie 2020
 */
import Fraction from 'fraction.js';

export type Size = {
  width: number,
  height: number
};

export function frac(num: number | string | Fraction, den?: number): Fraction {
  if (typeof num === 'string') {
    return new Fraction(num);
  }
  if (num instanceof Fraction) {
    return num;
  }

  if (typeof den === 'number') {
    return new Fraction(num, den);
  }

  return new Fraction(num);
}

export const defaultAR = frac(16, 9);

export function calcHeight(width: number | Fraction, ar: Fraction, exact = true): number {
  const f = frac(width).div(ar);

  if (exact) {
    return f.valueOf();
  }
  return f.round().valueOf();
}

export function calcWidth(height: number | Fraction, ar: Fraction, exact = true): number {
  const f = frac(height).mul(ar);

  if (exact) {
    return f.valueOf();
  }
  return f.round().valueOf();
}

export function fround<T extends number | Fraction>(x: T): T {
  if (typeof x === 'number') {
    return <T>Math.round(x);
  }
  return (x as Fraction).round() as T;
}
