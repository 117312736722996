export type CancelToken<T> = {
  requested: boolean,
  throwIfRequested: () => void,
  promise: Promise<T>,
};

export function createCancelToken<T>(): {
  token: CancelToken<T>,
  cancel: () => void,
} {

  let cancel: () => void = () => {
    console.error(new Error('Cancel token has been called, '
      + 'but was created with createCancelToken so it is broken and never worked! '
      + 'This is a noop!'));
  };

  const token: CancelToken<T> = {
    requested: false,

    throwIfRequested() {
      if (token.requested) {
        throw new Error('Action cancelled');
      }
    },

    promise: new Promise(resolve => {
      cancel = () => {
        // the reason property can be checked
        // synchronously to see if you're cancelled
        if (token.requested) { return; }

        token.requested = true;

        // @ts-ignore
        resolve();
      };
    }),
  };

  return { token, cancel };
}

const nop = () => {};

export function ignorePromiseOnCancel<T>(
  promise: Promise<T>,
  token?: CancelToken<T> | null | undefined,
): Promise<T> {
  if (!token) { return promise; }

  return new Promise((resolve, reject) => {
    let _resolve = resolve;
    let _reject = reject;

    token.promise.then(() => {
      _resolve = nop;
      _reject = nop;
    });

    promise.then(
      value => { _resolve(value); },
      reason => { _reject(reason); },
    );
  });
}
