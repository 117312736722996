// @flow

import AlertStore from '../stores/AlertStore';

export default function triggerLoginWithFacebook(callback: (facebookToken: string) => void) {
  if (!window.FB) {
    // please accept cookies.
    AlertStore.showSnackbar({
      message: 'In order to log in with Social Media, please accept the cookies from the bottom of the screen.',
      type: 'error',
      duration: undefined,
    });

    return;
  }
  window.FB.login(async (res: any) => {
    if (res.status !== 'connected') {
      console.error('User cancelled login or did not fully authorize.');
      return;
    }
    callback(res.authResponse.accessToken);
  }, {
    scope: 'email',
  });
}
