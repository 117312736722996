import React from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import FourKIcon from '@material-ui/icons/FourK';
import SpeedIcon from '@material-ui/icons/Speed';
import SettingsIcon from '@material-ui/icons/Settings';
import MemoryIcon from '@material-ui/icons/Memory';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';

import classes from './TinyVid.module.scss';
import TinyVidDownload from './TinyVidDownloadButton';
import DividerWithText from './request-access/DividerWithText';
import { ReactComponent as FPSIcon } from './60fps_select_black_24dp.svg';
import { ReactComponent as AV1Logo } from './AV1_logo.svg';
import VideoEmbedWithControls from './VideoEmbedWithControls';
import { eventList } from './aive/src/tracking/events';

function getOS() {
  const app = navigator.appVersion;

  if (app.indexOf('Win') !== -1) {
    return 'win';
  }
  if (app.indexOf('Mac') !== -1) {
    return 'mac';
  }

  return 'linux';
}

type DownloadMetaData = {
  files: {
    blockMapSize: number,
    sha512: string,
    size: number,
    url: string,
  }[],
  path: string,
  releaseDate: string,
  sha512: string,
  url: string,
  version: string,
};

const tinyVidVideo = {
  640: 'https://d2yveowal8ut5x.cloudfront.net/tinyvid_landing_480p.mp4',
  1280: 'https://d2yveowal8ut5x.cloudfront.net/tinyvid_landing_720p.mp4',
  1920: 'https://d2yveowal8ut5x.cloudfront.net/tinyvid_landing_1080p.mp4',
  3860: 'https://d2yveowal8ut5x.cloudfront.net/tinyvid_landing_4k.mp4',
};

function TinyVid() {
  const [downloadData, setDownloadData] = React.useState<DownloadMetaData | null>(null);

  const downloadTinyVid = React.useCallback(() => {
    // add track event for TinyVid Download
    if (!downloadData) return;

    window.open(downloadData.url, '_blank');
    eventList.tinyVid.download({ type: getOS() });
  }, [downloadData]);

  React.useEffect(() => {
    fetch('https://download.tinyvid.io/metadata')
      .then(data => data.json())
      .then(data => {
        setDownloadData(data[getOS()].x64);
      });
  }, []);

  return (
    <div className={classes.tinyVidContainer}>
      <Helmet>
        <title>TinyVid - The Free Video Compressor by Kamua</title>
        <meta property="og:title" content="TinyVid - The Free Video Compressor by Kamua" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://kamua.com/tinyvid" />
        <meta property="og:image" content="https://kamua.com/img/TinyVid_Feature_Image.gif" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="TinyVid - The Free Video Compressor by Kamua" />
        <meta
          name="description"
          content="TinyVid by Kamua - Trim and compress video files to fit any size limitation"
        />
      </Helmet>
      <div className={classes.topSection}>
        <div className={classes.gridWrapper}>
          <div className={classNames(classes.grid, classes.gridQ)}>
            <div className={classes.gridFade} />
          </div>
          <div className={classes.grid}>
            <div className={classes.gridLines} />
          </div>
        </div>

        <div className={classes.tinyHero}>
          <h1>
            Make your videos <small>tiny.*</small>
            <span>*FOR FREE!!!1</span>
          </h1>
        </div>
        <div className={classes.logo}>
          <img src="/img/tinyvid-logo.svg" alt="TinyVid Logo" />
        </div>

        <div className={classes.mainDownload}>
          <TinyVidDownload
            downloadFunction={downloadTinyVid}
            version={downloadData?.version}
            size={downloadData?.files[0].size}
          />
        </div>

        <VideoEmbedWithControls video={tinyVidVideo} poster="/img/tinyvid-placeholder.jpg" />

        <div className={classNames(classes.section, classes.whatItDoes)}>
          <h2>
            Set your desired file size and TinyVid will make it happen.
          </h2>
          <h3>
            TinyVideo is built to solve a very modern headache: <br />
            getting a video to fit a file size limitation.
          </h3>
        </div>
      </div>

      <div className={classNames(classes.section, classes.hiwSection)}>
        <div className={classes.content}>
          <h3>How it works</h3>

          <ul className={classes.hiwList}>
            <li>
              Download our free app (Works on Windows, Mac or Linux)
              <br />
              <TinyVidDownload
                downloadFunction={downloadTinyVid}
                version={downloadData?.version}
                size={downloadData?.files[0].size}
              />
            </li>
            <li>
              Choose the file you want to compress
            </li>
            <li>
              Choose the section of the video you want to compress*<br />
              <small>*Optional</small>
            </li>
            <li>
              Choose your target file size (or quality) <br />
              <div className={classes.fileSizes}>
                <div className={classes.socialSize}>
                  <div className={classes.fileLogo}>
                    <img src="/icons/social/Discord-Logo+Wordmark-White.svg" alt="Discord Logo" />
                  </div>
                  <div className={classes.size}>
                    8MB (Free)
                  </div>
                </div>
                <div className={classes.socialSize}>
                  <div className={classes.fileLogo}>
                    <img className={classes.whatsappLogo} src="/icons/social/whatsapp-logo.svg" alt="Whatsapp Logo" />
                  </div>
                  <div className={classes.size}>
                    64MB
                  </div>
                </div>
                <div className={classes.socialSize}>
                  <div className={classes.fileLogo}>
                    <img src="/icons/social/Discord-Logo+Wordmark-White.svg" alt="Discord Logo" />
                  </div>
                  <div className={classes.size}>
                    100MB (Nitro)
                  </div>
                </div>
              </div>
              <DividerWithText>or</DividerWithText>
              <div className={classes.customSize}>
                Custom Size <br /><small>Any custom MB</small>
              </div>
              <DividerWithText>or</DividerWithText>
              <div className={classes.customQuality}>
                Custom Quality <br /><small>From 40% to 100%</small>
              </div>
            </li>
            <li>Go!</li>
          </ul>
        </div>
      </div>

      <div className={classNames(classes.section, classes.otherFeatures)}>
        <div className={classes.content}>
          <h3>Other Features</h3>

          <div className={classes.features}>
            <div className={classNames(classes.feature, classes.featuredFeature)}>
              <div className={classes.featureIcon}>
                <CameraEnhanceIcon />
              </div>
              <div className={classes.featureDescription}>
                <span className={classes.featureTitle}>Quality Analysis</span>
                <small>
                  Based on the compression level you choose,
                  we&apos;ll tell you <b>before</b> processing if your video will:
                  <br /><br />
                  look good,<br />
                  have artifacts,<br />
                  will be of {' '}
                  <span role="img" aria-label="of potato quality">🥔</span> quality<br />
                  or even if you&apos;re wasting precious space!
                </small>
              </div>
            </div>
            <div className={classes.feature}>
              <div className={classes.featureIcon}>
                <FourKIcon />
              </div>
              <div className={classes.featureDescription}>
                <span className={classes.featureTitle}>Easily change Resolution</span>
                <small>360p, 480p (SD), 720p (HD), 1080p (FHD), 2160p (4K)</small>
              </div>
            </div>
            <div className={classes.feature}>
              <div className={classes.featureIcon}>
                <FPSIcon />
              </div>
              <div className={classes.featureDescription}>
                <span className={classes.featureTitle}>Change the FPS</span>
                <small>Frames per Second or Frame rate</small>
              </div>
            </div>
            <div className={classes.feature}>
              <div className={classes.featureIcon}>
                <SpeedIcon />
              </div>
              <div className={classes.featureDescription}>
                <span className={classes.featureTitle}>Processing Speed Selection</span>
                <small>From super fast to super Compression</small>
              </div>
            </div>
          </div>
        </div>
        <TinyVidDownload
          downloadFunction={downloadTinyVid}
          version={downloadData?.version}
          size={downloadData?.files[0].size}
        />
      </div>

      <div className={classNames(classes.section, classes.comingSoonFeatures)}>
        <div className={classes.content}>
          <h3>Features Coming Soon</h3>

          <div className={classes.features}>
            <div className={classes.feature}>
              <div className={classes.featureIcon}>
                <SettingsIcon />
              </div>
              <div className={classes.featureDescription}>
                <span className={classes.featureTitle}>Settings Page</span>
                <small>With even more options!</small>
              </div>
            </div>
            <div className={classes.feature}>
              <div className={classes.featureIcon}>
                h265
              </div>
              <div className={classes.featureDescription}>
                <span className={classes.featureTitle}>Support for H265 encoding</span>
              </div>
            </div>
            <div className={classes.feature}>
              <div className={classes.featureIcon}>
                <AV1Logo />
              </div>
              <div className={classes.featureDescription}>
                <span className={classes.featureTitle}>Support for AV1</span>
              </div>
            </div>
            <div className={classes.feature}>
              <div className={classes.featureIcon}>
                <MemoryIcon />
              </div>
              <div className={classes.featureDescription}>
                <span className={classes.featureTitle}>Support for Hardware Encoding</span>
                <small>Nvidia NVENC, Intel QuickSync, AMD AMF, Vulkan</small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(classes.section, classes.moreAboutTiny)}>
        <div className={classes.content}>
          <h3>What else?</h3>
          <div className={classes.text}>
            <p>
              TinyVideo is a downloadable application for Mac, Windows & Linux, built by <a href="https://kamua.com">Kamua</a>. The primary focus is on gamers that need to quickly trim their recordings & make sure their videos are sharable on Discord.
            </p>

            <p>
              But it also comes in handy for people who want to share a video,
              but the place where they need to share it has a size restriction
              (like the 64MB limit on Whatsapp)
            </p>

            <p>
              It also comes in handy to make sure
              it&apos;s as compressed as possible before uploading it to your WordPress site.
            </p>

            <p>
              Or when you have a weird video file that your computer
              can&apos;t even play (like a .mkv, .avi, or even a .flv file),
              and you need to embed in a PowerPoint presentation.
            </p>

            <p>TinyVid is here to convert / compress and trim your videos!</p>

            <div style={{ textAlign: 'center' }}>
              <TinyVidDownload
                downloadFunction={downloadTinyVid}
                version={downloadData?.version}
                size={downloadData?.files[0].size}
              />
            </div>

            <p>
              If you need to do more than what TinyVid offers, like:
              <ul>
                <li>
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a href="https://kamua.com/blog/2021/01/12/youtube-to-tiktok/" target="_blank">
                    Auto-Converting your YouTube videos to TikToks
                  </a>
                </li>
                <li>
                  Adding automated Captions to your mobile videos
                  (we support over 60 languages!)
                </li>
              </ul>
              ...then come and give <a href="https://kamua.com">Kamua</a> a try!
            </p>

            <div className={classes.tryKamua}>
              <Button
                href="https://kamua.com"
                variant="contained"
                color="secondary"
                size="large"
              >
                Give Kamua a try!
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(classes.section, classes.footer)}>
        <div className={classes.links}>
          <a href="https://kamua.com/blog/terms-of-use/">Terms of Use</a>
          <a href="https://kamua.com/blog/privacy-policy">Privacy Policy</a>
          <a href="https://kamua.com/blog/cookie-policy">Cookie Usage</a>
          <a href="mailto:hello@kamua.com">Contact Us!</a>
        </div>
      </div>
    </div>
  );
}

export default React.memo(TinyVid);
